import { useState, useCallback } from "react";
import radio_button_on from "../assets/radio_button_on.svg";
import radio_button_off from "../assets/radio_button_off.svg";
import user_image from "../assets/user_image@2x.png";
import agency_radio_selected from "../assets/agency_radio_selected@2x.png"
import agency_radio from "../assets/agency_radio@2x.png"
import { toast } from "react-toastify";
import apiService from "../utils/ApiService";
import { HttpStatusCode } from "axios";
import CreateTopic from "../components/CreateTopic";
import AddPeople from "../components/AddPeople";
import PortalPopup from "../components/PortalPopup";

const CreateGroup = ({ refreshLeftNavigation }) => {
  const [groupNameValue, setgroupName] = useState('');
  const [descriptionValue, setDescription] = useState('');
  const [visibilityValue, setVisibility] = useState(true);
  const [selectedPostingOption, setPostingOption] = useState(["1", "2", "3"]);
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [isAddPeoplePopupOpen, setAddPeoplePopupOpen] = useState(false);
  const [isCreateTopicPopupOpen, setCreateTopicPopupOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const openAddPeoplePopup = useCallback(() => {
    setAddPeoplePopupOpen(true);
  }, []);

  const closeAddPeoplePopup = useCallback(() => {
    setAddPeoplePopupOpen(false);
  }, []);

  const openCreateTopicPopup = useCallback(() => {
    setCreateTopicPopupOpen(true);
  }, []);

  const closeCreateTopicPopup = useCallback(() => {
    setCreateTopicPopupOpen(false);
  }, []);

  const handleSelectedMembers = (data) => {
    setSelectedGroupMembers([]);
    setSelectedGroupMembers(data);
  };

  const handleOptionChange = (value) => {
    setPostingOption(value);
  };

  const handleChange = (value) => {
    setVisibility(!value);
  };

  const postRequest = async () => {
    const newErrors = {};
    if (!groupNameValue.trim()) {
      newErrors.email = 'groupNameValue is required';
      toast.error('Please provide a group name. This field is mandatory.');
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let userIds = selectedGroupMembers.map(m => m.id);
      userIds.push(window.product_userId);
      const postData = { "group_name": groupNameValue, "user_id": window.product_userId, "member": userIds, "group_img_url": null, 'subscriber_id': window.agencyId, "description": descriptionValue };

      let response;
      try {
        // setIsLoading(true);
        response = await apiService.post('shopperpad/create-group/', postData);
        if (response.status === 'success') {
          // setIsLoading(false);
          toast.success(response.msg);
          refreshLeftNavigation();
        }
        else if (response.status_code === HttpStatusCode.BadRequest) {
          toast.error(response.msg);
        }
        else {
          toast.error(response.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error?.response?.data?.message || 'An error occurred');
        } else {
        }
      }
    }
  };

  return (
    <>
      {/* <div
        style={{
          textAlign: "center",
          display: "block",
          padding: 30,
          margin: "auto",
        }}
        className="w-full relative bg-white h-[957px] flex flex-row flex-wrap items-start justify-start" id="create-group-dv"> */}
        <div className="w-[1080px] flex flex-row items-center justify-start chatMain">
        <section
          className="flex-1 bg-white flex flex-col items-center justify-start min-w-[320px] text-left text-lg text-headings font-base-md-regular"
          id="content_pan"
        >
          <div
            className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]"
            id="content_pan_header_create_group"
          >
            <button className="cursor-pointer [border:none] p-2 bg-[transparent] flex flex-row items-center justify-center">
              {/* <img
                className="w-[25px] relative h-[25px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/icon_back@2x.png"
              /> */}
            </button>
            <div className="flex-1 flex flex-row items-center justify-start">
              <b className="relative tracking-[0.01em] leading-[140%]">
                Create Group
              </b>
            </div>
          </div>
          <div
            className="self-stretch flex flex-col items-center justify-start p-6 gap-[48px] text-base"
            id="create_group"
          >
            <div className="w-full overflow-hidden flex flex-row flex-wrap items-start justify-center gap-[48px] max-w-[960px] min-w-[320px] responsive-overflow">
              <div className="flex-1 flex flex-col items-center justify-start min-w-[320px]">
                <div className="self-stretch flex flex-col items-start justify-start p-4 gap-[8px] text-xs text-placeholder-text border-[1px] border-solid border-stroke-light">
                  <div className="self-stretch h-5 flex flex-row items-center justify-start">
                    <b className="flex-1 relative leading-[120%] uppercase">
                      Group Information
                    </b>
                  </div>
                  <div className="self-stretch h-5 flex flex-row items-center justify-start">
                    <b className="flex-1 relative leading-[120%] uppercase">
                      Name
                    </b>
                  </div>
                  <input
                    className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start p-2 text-headings border-[1px] border-solid border-stroke"
                    placeholder="Group Name"
                    type="text"
                    value={groupNameValue}
                    onChange={(e) => setgroupName(e.target.value)}
                  />
                  <div className="self-stretch h-5 flex flex-row items-center justify-start">
                    <b className="flex-1 relative leading-[120%] uppercase">
                      description
                    </b>
                  </div>
                  <textarea
                    className="bg-white font-base-md-regular text-base [outline:none] self-stretch rounded-md box-border h-[88px] flex flex-row items-start justify-start py-2 px-3 text-headings border-[1px] border-solid border-stroke"
                    placeholder="Add a group description..."
                    value={descriptionValue}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* <div className="self-stretch relative tracking-[0.02em] leading-[140%] text-right">
                  91/120
                </div> */}
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px] border-[1px] border-solid border-stroke-light">
                  <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch h-5 flex flex-row items-center justify-start gap-[8px]">
                    <b className="relative text-xs leading-[120%] uppercase font-base-md-regular text-placeholder-text text-left">
                      Visibility
                    </b>
                    <div className="flex-1 relative text-xs leading-[120%] font-base-md-regular text-paragraph text-left">
                      Who can view this group?
                    </div>
                  </button>
                  <form className="m-0 self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch h-10 flex flex-row items-center justify-start py-1 px-0 box-border gap-[8px]">
                      <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={visibilityValue ? agency_radio_selected : agency_radio}
                        onClick={() => handleChange(visibilityValue)}
                      />
                      <div className="flex-1 relative text-base leading-[140%] text-headings text-left">
                        <b className="tracking-[0.01em] font-base-md-regular">
                          Public
                        </b>
                        <span className="tracking-[0.02em] font-base-md-regular">
                          {" "}
                          - Visible to everyone
                        </span>
                      </div>
                    </div>
                    <div className="self-stretch h-10 flex flex-row items-center justify-start py-1 px-0 box-border gap-[8px]">
                      <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={!visibilityValue ? agency_radio_selected : agency_radio}
                        onClick={() => handleChange(visibilityValue)}
                      />
                      <div className="flex-1 relative text-base leading-[140%] text-headings text-left">
                        <b className="tracking-[0.01em] font-base-md-regular">
                          Private
                        </b>
                        <span className="tracking-[0.02em] font-base-md-regular">
                          {" "}
                          - Visible to only specific people
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="self-stretch flex flex-col items-start justify-start p-4 gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <b className="self-stretch relative tracking-[0.01em] leading-[140%]">
                      Posting Permission
                    </b>
                    <div className="self-stretch relative tracking-[0.02em] leading-[140%] text-paragraph">
                      Who can post in this board?
                    </div>
                  </div>
                  <form className="m-0 self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch h-10 flex flex-row items-center justify-start py-1 px-0 box-border gap-[8px]">
                        <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={selectedPostingOption === '["1","2","3"]' ? agency_radio_selected : agency_radio}
                        onClick={() => handleOptionChange('["1","2","3"]')}
                      />
                      <div className="flex-1 relative text-base tracking-[0.02em] leading-[140%] font-base-md-regular text-headings text-left">
                        Everyone
                      </div>
                    </div>
                    <div className="self-stretch h-10 flex flex-row items-center justify-start py-1 px-0 box-border gap-[8px]">
                        <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={selectedPostingOption === '["1","2"]' ? agency_radio_selected : agency_radio}
                        onClick={() => handleOptionChange('["1","2"]')}
                      />
                      <div className="flex-1 relative text-base tracking-[0.02em] leading-[140%] font-base-md-regular text-headings text-left">
                        Owners and Admins Only
                      </div>
                    </div>
                    <div className="self-stretch h-10 flex flex-row items-center justify-start py-1 px-0 box-border gap-[8px]">
                        <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={selectedPostingOption === '["1"]' ? agency_radio_selected : agency_radio}
                        onClick={() => handleOptionChange('["1"]')}
                      />
                      <div className="flex-1 relative text-base tracking-[0.02em] leading-[140%] font-base-md-regular text-headings text-left">
                        Owners Only
                      </div>
                    </div>
                  </form>
                </div> */}
                {/* <div className="self-stretch flex flex-row items-start justify-start p-4 gap-[16px] border-[1px] border-solid border-stroke-light">
                  <b className="flex-1 relative tracking-[0.01em] leading-[140%]">
                    Turn off the notifications
                  </b>
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 object-contain"
                    alt=""
                    src={radio_button_off}
                  />
                </div>
                <div className="self-stretch flex flex-row items-start justify-start p-4 gap-[16px]">
                  <b className="flex-1 relative tracking-[0.01em] leading-[140%]">
                    Allow people to turn off the notifications
                  </b>
                  <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 object-contain"
                    alt=""
                    src={radio_button_off}
                  />
                </div> */}
              </div>
              <div className="flex-1 h-[733px] flex flex-col items-center justify-start min-w-[360px] text-placeholder-text  people-hgt-main ">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px] people-hgt-blg">
                  <div className="self-stretch h-5 flex flex-row items-center justify-start text-xs">
                    <b className="flex-1 relative leading-[120%] uppercase">
                      People
                    </b>
                  </div>
                  <a
                    className="[text-decoration:none] self-stretch relative tracking-[0.01em] leading-[140%] font-bold text-secondary-button-link pointercursor"
                    href="#" onClick={() => openAddPeoplePopup()}
                  >
                    + Add People
                  </a>
                  <div className="self-stretch flex flex-col items-start justify-start text-headings">
                    {selectedGroupMembers.length > 0 && (
                      selectedGroupMembers.map((item) => (
                        <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-4">
                          <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                            <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                              <img
                                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full z-[0]"
                                alt=""
                                src={user_image}
                              />
                              <div className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] bg-stroke box-border h-3 z-[1] border-[1.5px] border-solid border-app-background" />
                            </div>
                            <div className="flex-1 flex flex-row items-end justify-start">
                              <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                                {item.lastname ? item.lastname + ', ' + item.firstname : item.firstname}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                {/* <div className="self-stretch flex-1 flex flex-col items-start justify-start p-4 gap-[8px] people-hgt-blg">
                <div className="self-stretch h-5 flex flex-row items-center justify-start text-xs">
                  <b className="flex-1 relative leading-[120%] uppercase">
                    Topics
                  </b>
                </div>
                <a
                  className="[text-decoration:none] self-stretch relative tracking-[0.01em] leading-[140%] font-bold text-secondary-button-link"
                  href="#"
                >
                  + Create Topic
                </a>
                <div className="self-stretch flex flex-col items-start justify-start text-headings">
                  <div className="self-stretch flex flex-row items-center justify-start py-3 px-4">
                    <div className="flex-1 relative leading-[120%] font-semibold">
                      /topic-name
                    </div>
                  </div>
                </div>
              </div>
              <button className="cursor-pointer [border:none] p-4 bg-[transparent] self-stretch flex flex-row items-center justify-center gap-[8px] border-[1px] border-solid border-stroke-light">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src={exit}
                />
                <div className="flex-1 relative text-base tracking-[0.02em] leading-[140%] font-base-md-regular text-brown text-left">
                  Leave Group
                </div>
              </button>
              <button className="cursor-pointer [border:none] p-4 bg-[transparent] self-stretch flex flex-row items-center justify-center gap-[8px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src={trash}
                />
                <div className="flex-1 relative text-base tracking-[0.02em] leading-[140%] font-base-md-regular text-brown text-left">
                  Delete
                </div>
              </button> */}
              </div>
            </div>
            <button className="cursor-pointer [border:none] p-0 bg-primary-button-link w-full rounded-13xl h-12 flex flex-row items-center justify-center max-w-[328px]" type="submit" onClick={() => postRequest()}>
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Create
              </b>
            </button>
          </div>
        </section>
      </div>
      {isAddPeoplePopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddPeoplePopup}
        >
          <AddPeople onClose={closeAddPeoplePopup} sendDataToParent={handleSelectedMembers} selectedGroupMembers={selectedGroupMembers} />
        </PortalPopup>
      )}
      {isCreateTopicPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCreateTopicPopup}
        >
          <CreateTopic onClose={closeCreateTopicPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default CreateGroup;
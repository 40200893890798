import Closeicon from "../assets/Close-icon.svg"
import apiService from "../utils/ApiService";
import { useState } from "react";
import { toast } from "react-toastify";

const CreateTopic = (props) => {
  const { onClose, groupId } = props
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  async function handleSubmit() {
    try {
      if (description.length > 120) {
        toast.error("Description must be 120 characters or less.");
        return;
      }

      const response = await apiService.post("shopperpad/add-topic/", {
        name: name,
        description: description,
        group_id: groupId
      });
      console.log("Response from POST request:", response);
      onClose();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="relative w-full flex flex-col items-center justify-start text-left text-lg text-headings font-base-md-regular  add-people-blg">
      <div className="relative flex flex-col items-center justify-start text-left text-lg text-headings font-base-md-regular border-[1px] border-solid border-stroke-light">
        <div
          className="w-[360px] bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]"
          id="content_pan_header_create_group"
        >
          <div className="flex-1 flex flex-row items-center justify-start">
            <b className="relative tracking-[0.01em] leading-[140%]">
              Create Topic
            </b>
          </div>
          <button className="cursor-pointer [border:none] p-2 bg-[transparent] flex flex-row items-center justify-center">
            <img
              className="w-[25px] relative h-[25px] overflow-hidden shrink-0 object-cover" onClick={onClose}
              alt=""
              src={Closeicon}
            />
          </button>
        </div>
        <div className="self-stretch bg-white flex flex-col items-start justify-start p-4 text-xs text-placeholder-text">
          <div className="self-stretch h-5 flex flex-row items-center justify-start">
            <b className="flex-1 relative leading-[120%] uppercase">Name</b>
          </div>
          <input
            className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start p-2 text-headings border-[1px] border-solid border-stroke"
            placeholder=""
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="self-stretch h-5 flex flex-row items-center justify-start">
            <b className="flex-1 relative leading-[120%] uppercase">
              description
            </b>
          </div>
          <textarea
            className="bg-white font-base-md-regular text-base [outline:none] self-stretch rounded-md box-border h-[88px] flex flex-row items-start justify-start py-2 px-3 text-headings border-[1px] border-solid border-stroke"
            placeholder=""
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="self-stretch relative tracking-[0.02em] leading-[140%] text-right">
            {description.length}/120
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-col items-center justify-start pt-0 px-0 pb-4">
          <button className="cursor-pointer [border:none] p-0 bg-primary-button-link w-full rounded-13xl h-12 flex flex-row items-center justify-center max-w-[328px]" onClick={handleSubmit}>
            <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
              Create
            </b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTopic;
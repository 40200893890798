import { useState } from "react";
import agency_logo from "../assets/agency_logo@2x.png"
import agency_radio_selected from "../assets/agency_radio_selected@2x.png"
import agency_radio from "../assets/agency_radio@2x.png"

const Containerdiv = ({ item, selectedValue, handleChange }) => {
  const OnImagehandleChange = (id) => {
    selectedValue = id;
    window.agencyId = id;
    handleChange(id);
  }
  return (
    <div className="self-stretch flex flex-row items-center justify-start py-4 px-6 gap-[16px] text-left text-lg text-headings font-base-md-regular">
      <img
        className="w-10 rounded h-10 object-cover"
        alt=""
        src={agency_logo}
      />
      <b className="flex-1 relative tracking-[0.01em] leading-[140%]">
        {item.full_name}
      </b>
      <img
        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
        alt=""
        src={item.id === selectedValue || item.id === window.agencyId ? agency_radio_selected : agency_radio}
        onClick={() => OnImagehandleChange(item.id)}
      />
    </div>
  );
};

export default Containerdiv;

import { useState, useEffect } from "react";
import Closeicon from "../assets/Close-icon.svg";
import otlperson from "../assets/otlperson.svg";
import checkbox_checked from "../assets/checkbox-checked.svg";
import checkbox_unchecked from "../assets/unchecked.svg";
import { toast } from "react-toastify";

const AddPeople = ({ onClose, sendDataToParent, selectedGroupMembers }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const updatedUserList = [];
    if (window.homeResponse.users && window.homeResponse.users.length > 0) {
      updatedUserList.push(
        ...window.homeResponse.users
          .map(element => ({
            firstname: element.first_name,
            lastname: element.last_name,
            profileimage: element.profile_image,
            email: element.email,
            id: element.id,
            lastmessage: element.last_message,
            isonline: element.isOnline,
            unread: element.unread,
            profile_image: element.profile_image,
            created_at: element.created_at,
            selectedMembers: false,
          }))
      );
      if (selectedGroupMembers && selectedGroupMembers.length > 0) {
        for (let index = 0; index < selectedGroupMembers.length; index++) {
          updatedUserList.find(element => element.id === selectedGroupMembers[index].id).selectedMembers = true;
        }
      }
      setUsers(updatedUserList);
    }
  }, []);

  const sendData = () => {
    if (users.filter(f => f.selectedMembers === true).length > 0) {
      sendDataToParent(users.filter(f => f.selectedMembers === true));
      onClose();
    } else {
      toast.error('Please select at least one member');
    }
  };

  const handleItemClick = (itemId) => {
    setUsers(prevItems => (
      prevItems.map(item => {
        if (item.id === itemId) {
          return { ...item, selectedMembers: !item.selectedMembers };
        }
        return item;
      })
    ));
  };

  return (
    <>
      <div className="relative w-full flex flex-col items-center justify-start text-left text-lg text-headings font-base-md-regular add-people-blg">
        <div className="relative flex flex-col items-center justify-start text-left text-lg text-headings font-base-md-regular border-[1px] border-solid border-stroke-light">
          <div
            className="min-w-[420px] bg-app-background h-20 flex flex-row items-center justify-start py-0 max-w-[320px] px-4 box-border gap-[16px] "
            id="content_pan_header_create_group add-people-nav"
          >
            <div className="flex-1 flex flex-row items-center justify-start add-people-nav">
              <b className="relative tracking-[0.01em] leading-[140%]">
                Add People
              </b>
            </div>
            <button className="cursor-pointer [border:none] p-2 bg-[transparent] flex flex-row items-center justify-center" onClick={onClose}>
              <img
                className="w-[25px] relative h-[25px] overflow-hidden shrink-0 object-cover"
                alt=""
                src={Closeicon}
              />
            </button>
          </div>
          <div className="w-[421px] self-stretch bg-white h-[376px] flex flex-row flex-col items-center justify-start p-4 box-border text-base margin-auto-dv">
            {users.length > 0 && (
              users.map((item) => (
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-4 gap-[16px]">
                    <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border">
                      <img
                        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                        alt=""
                        src={otlperson}
                      />
                    </div>
                    <div className="flex-1 flex flex-row items-center justify-start" id="add-people-dv">
                      <div className="flex-1 flex flex-row items-end justify-start">
                        <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                          {item.lastname ? item.lastname + ', ' + item.firstname : item.firstname}
                        </div>
                      </div>
                    </div>
                    <img
                      className="w-6 relative h-6 overflow-hidden shrink-0"
                      alt=""
                      src={item.selectedMembers === true ? checkbox_checked : checkbox_unchecked}
                      onClick={() => handleItemClick(item.id)}
                    />
                    <label>{item.selectedMembers}</label>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="self-stretch bg-white flex flex-col items-center justify-start pt-0 px-0 pb-4">
            <button className="cursor-pointer [border:none] p-0 bg-primary-button-link w-full rounded-13xl h-12 flex flex-row items-center justify-center max-w-[328px]" onClick={sendData}>
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Add
              </b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPeople;
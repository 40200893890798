import ChatScreen from "../pages/ChatScreen";
import CreateGroup from "../pages/CreateGroup";
import InvitePeople from "../pages/InvitePeople";

const ContentPannel = (props) => {
    const { showComponent, groupdata, refreshLeftNavigation, invitations } = props
    return (
        <>
            {showComponent === 'GroupChat' && <ChatScreen data={groupdata} refreshLeftNavigation={refreshLeftNavigation} />}
            {showComponent === 'InvitePeople' && <InvitePeople refreshLeftNavigation={refreshLeftNavigation} invitations={invitations} />}
            {showComponent === 'CreateGroup' && <CreateGroup refreshLeftNavigation={refreshLeftNavigation} />}
        </>
    );
};

export default ContentPannel;
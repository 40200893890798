const FrameComponent = ({ slideIcon }) => {
  return (
    <div className="self-stretch flex-1 flex flex-col items-center justify-center text-left text-9xl text-primary-button-link font-base-md-regular">
      <div className="w-full flex flex-col items-start justify-center py-4 px-0 box-border gap-[24px] max-w-[400px]">
        <img
          className="w-16 relative h-16 overflow-hidden shrink-0 object-cover"
          alt="Multi-Modal Communication"
          src={slideIcon}
        />
        <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
          Multi-Modal Communication
        </h2>
        <p className="m-0 self-stretch relative text-lg tracking-[0.03em] leading-[140%] text-headings">
          A comprehensive suite of communication tools, including text chat,
          audio calls, video calls, and even livestreams
        </p>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
          <div className="w-8 relative rounded-2xl bg-primary-button-link h-1" />
          <div className="w-4 relative rounded-2xl bg-stroke h-1" />
          <div className="w-4 relative rounded-2xl bg-stroke h-1" />
          <div className="w-4 relative rounded-2xl bg-stroke h-1" />
          <div className="w-4 relative rounded-2xl bg-stroke h-1" />
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;

import React from 'react';

const TextWithLink = ({ inputText, isReply, onClick }) => {
  // Function to extract URLs from the input text
  const extractLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    return matches || [];
  };

  // Function to render text with clickable links
  const renderTextWithLinks = (text) => {
    if (!text) {
      // If inputText is null or empty, render a default message or return null
      return null;
    }

    const links = extractLinks(text);

    // if (links.length === 0) {
    //   return <span className='pointercursor' onClick={onClick}>{text}</span>;
    // }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split('\n'); // Split text by new line character

    return (
      <span>
        {parts.map((line, index) => (
          <React.Fragment key={index}>
            {line.split(urlRegex).map((part, partIndex) => {
              if (extractLinks(part).length > 0) {
                return (
                  <a
                    key={partIndex}
                    href={part}
                    style={{ color: isReply ? 'gray' : 'blue', textDecorationLine: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part}
                  </a>
                );
              }

              return (
                <span key={partIndex} className="pointercursor" onClick={onClick}>
                  {part}
                </span>
              );
            })}
            <br />
          </React.Fragment>
        ))}
      </span>
    );
  };

  return renderTextWithLinks(inputText);
};

export default TextWithLink;

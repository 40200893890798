import { useState, useEffect } from "react";
import Containerdiv from "../components/Containerdiv";
import CreateAgency from "./CreateAgency";
import HomeScreen from "./HomeScreen";
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";

const SelectAgency = () => {
  const [showComponent, setShowComponent] = useState(null);
  const [agencyList, setAgencyList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const OnCreateAgency = () => {
    setShowComponent('CreateAgency');
  }

  const OnNext = () => {
    if (selectedValue) {
      var agnecy = agencyList.find(f => f.id === selectedValue);
      window.agencyId = selectedValue;
      window.agencyName = agnecy.full_name;
      window.roleId = agnecy.role_id;
      localStorage.setItem('data', JSON.stringify({ 'product_userId': window.product_userId, 'agencyId': window.agencyId, 'firstName': window.firstName, 'lastName': window.lastName, 'mobile_number': window.mobile_number, "token": window.token, "agencyName": window.agencyName, "roleId": window.roleId, "email": window.email }));
    } else if (!selectedValue && !window.agencyId) {
      toast.error('Please select an agency');
    }else {
      localStorage.setItem('data', JSON.stringify({ 'product_userId': window.product_userId, 'agencyId': window.agencyId, 'firstName': window.firstName, 'lastName': window.lastName, 'mobile_number': window.mobile_number, "token": window.token, "agencyName": window.agencyName, "roleId": window.roleId, "email": window.email }));
    }

    if (selectedValue || window.agencyId) {
      setShowComponent('OnNext');
    }
  }

  const handleChange = (itemId) => {
    setSelectedValue(itemId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const postData = { email: window.email };
    try {
      const response = await apiService.post("shopperpad/get-agency-list/", postData);
      if (response) {
        setAgencyList(response.subscribers);
      } else {
        setShowComponent('CreateAgency');
        // toast.error("Resource not found.");
      }
      return response;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      } else if (error.response && error.response.status === 404) {
        setShowComponent('CreateAgency');
        // toast.error("Resource not found.");
      } else {
        toast.error(
          `Error: ${error.response ? error.response.status : "unknown"}`
        );
      }
      return error;
    }
  };

  if (showComponent === 'CreateAgency') {
    return <CreateAgency />;
  }
  else if (showComponent === 'OnNext') {
    return <HomeScreen />;
  }
  else {
    return (
      <div className="w-full relative bg-white h-[100vh] flex flex-col items-center justify-center p-16 box-border" id="select-agency-dv">
        <section
          className="max-w-[400px] flex-1 rounded-lg bg-gray box-border flex flex-col items-center justify-start p-6 gap-[40px] text-left text-9xl text-headings font-base-md-regular border-[1px] border-solid border-stroke-light"
          id="select_agency"
        >
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
              Hello there!
            </h2>
            <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%]">
              Looks like your email address or phone number are registered with
              these agencies. Select at least one agency to get started.
            </p>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-center justify-center">
            <div className="self-stretch flex-1 flex flex-col items-center justify-start" id="inner-select-agency-blg">
              {agencyList ? (
                agencyList.map((item) => (
                  <Containerdiv
                    key={item.id}
                    item={item}
                    selectedValue={selectedValue}
                    handleChange={handleChange}
                  />
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>

          </div>
          <div className="self-stretch flex flex-row items-center justify-center text-center text-lg text-secondary-button-link createagency-footer">
            <div className="self-stretch flex flex-col items-center justify-center p-6">
              <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-start justify-end" onClick={OnNext}>
                <div className="self-stretch rounded-13xl bg-primary-button-link h-12 flex flex-row items-center justify-center">
                  <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                    Next
                  </b>
                </div>
              </button>
            </div>
            <a
              className="[text-decoration:none] relative tracking-[0.01em] leading-[140%] font-bold text-[inherit]"
              href="#" onClick={OnCreateAgency}
            >
              Create New Agency
            </a>
          </div>
        </section>
      </div>
    );
  }
};

export default SelectAgency;

import { useState, useEffect } from "react";
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";
import user_image from "../assets/user_image@2x.png";
import vector from "../assets/vector.svg";
import Closeicon from "../assets/Close-icon.svg";

const InviteSent = ({refreshLeftNavigation}) => {
  const [contact, setcontact] = useState(null);

  useEffect(() => {
    const pageload = async () => {
    await getContact();
    };
    pageload();
  }, []);

  const getContact = async () => {
    const postData = { user_id: window.product_userId }

    let response;
    try {
      response = await apiService.post('shopperpad/user-invitation-sent/', postData);
      if (response.status_code === 200) {  
        const invitationsArray = Array.isArray(response.data) ? response.data : [response.data];
          if (invitationsArray.length > 0) {
            setcontact(invitationsArray);
          }
        
        return response.data;
      }
      else {
        toast.error('Request failed, Please try again later');
        return response;
      }
    } catch (error) {
      toast.error('Request failed, Please try again later');
      return error;
    }
  };

  const postRequest = async (selectedContacts) => {
    let postData = [];
    postData.push({ first_name: '', last_name: '', mobile_number: selectedContacts.mobile_number, countryCode: '', user: window.product_userId, subscriber: window.agencyId, });

    let response;
    try {
      response = await apiService.post('shopperpad/user-invitation/', postData);
      if (response.status_code === 200) {
        toast.success("Invitation sent successfully");
      }
      else if (response.status_code === HttpStatusCode.BadRequest) {
        if (response.error === 'Duplicate key violation') {
          toast.error("This user is already invited");
        } else {
          toast.error(response.msg || 'Bad Request error');
        }
      }
      else if (response.status_code === 108) {
        toast.error("This user is already invited");
      }
      else {
        toast.error('Request failed, Please try again later');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const response = error.response.data;
        if (response.error === 'Duplicate key violation') {
          toast.error('This user is already invited');
        } else {
          toast.error(response.msg);
        }
      } else {
        toast.error('Request failed, Please try again later');
      }
    }
  };

  const postRejectedRequest = async (selectedContacts) => {
    let postData = { logged_user_id: window.product_userId, id: selectedContacts.id, type: 'rejected', subscriber_id: window.agencyId };

    let response;
    try {
      response = await apiService.post('shopperpad/update-invitation-status/', postData);
      if (response.status_code === 200) {
        toast.success(response.msg);
        setcontact(contact.filter(contact => contact.id != selectedContacts.id));
        refreshLeftNavigation('InviteSent');
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error('Request failed, Please try again later');
    }
  };

  return (
    <div
      className="self-stretch flex-1 flex flex-col items-center justify-center p-16"
      id="tab_cotent_invite_sent  w-[320px]"
    >
      <div className="w-[320px] flex-1 rounded-lg bg-gray box-border flex flex-col items-center justify-start  p-6 gap-[40px] border-[1px] border-solid border-stroke-light min-w-[360px]">
        <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
          <h2 className="m-0 self-stretch relative text-inherit tracking-[0.02em] leading-[120%] font-bold font-inherit">
            Invitation Sent
          </h2>
          <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%] text-paragraph">
            Await people response and get ready to connect in the app
          </p>
        </div>
        <div className="self-stretch  overflow-y-auto shrink-0 flex flex-col items-start justify-start text-base">
          {contact ? (
            contact.map((item) => (
              <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-0 gap-[16px]">
                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                  <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border">
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                      alt=""
                      src={user_image}
                    />
                  </div>
                  <div className="flex-1 flex flex-row items-end justify-start">
                    <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                      {item.mobile_number}
                    </div>
                  </div>
                </div>
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-base tracking-[0.01em] leading-[140%] font-semibold font-base-md-regular text-paragraph text-left inline-block" onClick={(e) => postRequest(item)}>
                  Re-send
                </button>
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-7 relative h-7 overflow-hidden shrink-0" id="close-btn-blg" onClick={(e) => postRejectedRequest(item)}>
                  <img
                    className="absolute h-[70.57%] w-[70.57%] top-[15.21%] right-[28.21%] bottom-[25.21%] left-[28.21%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src={Closeicon}
                  />
                  {/* <img
                    className="absolute h-[43.57%] w-[43.57%] top-[28.21%] right-[28.21%] bottom-[28.21%] left-[28.21%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src={Closeicon}
                  /> */}
                </button>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InviteSent;

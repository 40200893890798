//ApiService.js
import axios from "axios";

class ApiService {
  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_URL;
    this.websocketURL = process.env.REACT_APP_WEBSOCKET_URL;
    this.websocket = null;
    this.websocketUserListURL = process.env.REACT_APP_WEBSOCKET_USERLIST_URL;
    this.websocketUserList = null
    this.websocketChatList = null
    this.websocketThreadList = null
    this.websocketTopicList = null
    this.messageCallback = null;
    this.uselistCallback = null;
    this.chatuselistCallback = null;
    this.threadlistCallback = null;
    this.topiclistCallback = null
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.token = JSON.parse(localStorage.getItem("data"))?.token;
  }

  checkInternetConnection = () => {
    return navigator.onLine;
  };

  // Common function for making GET requests
  get = async (endpoint, params = {}) => {
    const isConnected = await this.checkInternetConnection();
    if (!isConnected) {
      console.log("No internet connection");
      // Handle no internet connection case, maybe show an error message
      return "No internet connection";
    }

    try {
      const response = await axios.get(`${this.baseURL}${endpoint}`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error in GET request:", error);
      throw error;
    }
  };

  // Common function for making POST requests
  post = async (endpoint, data = {}) => {
    const isConnected = await this.checkInternetConnection();

    if (!isConnected) {
      console.log("No internet connection");
      // Handle no internet connection case, maybe show an error message
      return "No internet connection";
    }

    try {
      const response = await axios.post(`${this.baseURL}${endpoint}`, data);
      console.log(`${this.baseURL}${endpoint}`);
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  };

  // Common function for making POST requests
  put = async (endpoint, data = {}) => {
    const isConnected = await this.checkInternetConnection();

    if (!isConnected) {
      console.log("No internet connection");
      // Handle no internet connection case, maybe show an error message
      return "No internet connection";
    }

    try {
      const response = await axios.put(`${this.baseURL}${endpoint}`, data);
      console.log(`${this.baseURL}${endpoint}`);
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  };

  delete = async (endpoint, params = {}) => {
    const isConnected = await this.checkInternetConnection();

    if (!isConnected) {
      console.log("No internet connection");
      // Handle no internet connection case, maybe show an error message
      return "No internet connection";
    }

    try {
      const response = await axios.delete(`${this.baseURL}${endpoint}`, { data: params });
      console.log(`${this.baseURL}${endpoint}`);
      return response.data;
    } catch (error) {
      console.error("Error in DELETE request:", error);
      throw error;
    }
  };

  // WebSocket connection setup
  setupWebSocket = () => {
    this.token = JSON.parse(localStorage.getItem("data"))?.token;
    this.websocket = new WebSocket(this.websocketURL + "?token=" + this.token);

    // WebSocket event listeners
    this.websocket.onopen = () => {
      console.log("WebSocket Chat connection opened");
    };

    this.websocket.onmessage = (event) => {
      try {
        if (!JSON.parse(event.data).users_list) {
          const getmessageobj = {
            user_id: this.currentUser.id,
            type: "get_user_list",
          };
          this.sendUserListRequest(JSON.stringify(getmessageobj));
        }

        // Check if a callback is set and call it with the received message
        if (this.messageCallback) {
          this.messageCallback(JSON.parse(event.data));
        }
        // Handle WebSocket messages as needed
      } catch (error) {
        console.error("Error processing WebSocket Chat message:", error);
        // Handle the error, but do not close the WebSocket connection
      }
    };

    this.websocket.onclose = (event) => {
      console.log("WebSocket Chat connection closed");
      // Handle WebSocket connection closed
      if (event.code !== 1000 && event.code !== 1005) {
        this.reconnectWebSocket("CHAT");
      }
    };
  };

  // WebSocket userlist connection setup
  setupWebSocketUserList(onOpenCallback) {
    this.token = JSON.parse(localStorage.getItem("data"))?.token
    if (this.token) {
      this.websocketUserList = new WebSocket(this.websocketUserListURL + "?token=" + this.token);

      // WebSocket event listeners
      this.websocketUserList.onopen = () => {
        console.log("WebSocket User List connection opened");
        if (onOpenCallback) {
          onOpenCallback();
        }
      };

      this.websocketUserList.onmessage = (event) => {
        try {
          // Check if a callback is set and call it with the received message
          if (this.uselistCallback) {
            this.uselistCallback(JSON.parse(event.data));
          }
        } catch (error) {
          console.error("Error processing WebSocket User List message:", error);
          // Handle the error, but do not close the WebSocket connection
        }
      };

      this.websocketUserList.onclose = (event) => {
        console.log("WebSocket User List connection closed");
        // Handle WebSocket connection closed
        if (event.code !== 1000 && event.code !== 1005) {
          this.reconnectWebSocket("USERLIST");
        }
      };

      this.websocketUserList.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  };

  setupWebSocketChatList(onOpenCallback) {
    this.token = JSON.parse(localStorage.getItem("data"))?.token
    if (this.token) {
      this.websocketChatList = new WebSocket(this.websocketUserListURL + "?token=" + this.token);

      // WebSocket event listeners
      this.websocketChatList.onopen = () => {
        console.log("WebSocket chat List connection opened");
        if (onOpenCallback) {
          onOpenCallback();
        }
      };

      this.websocketChatList.onmessage = (event) => {
        try {
          // Check if a callback is set and call it with the received message
          if (this.chatuselistCallback) {
            this.chatuselistCallback(JSON.parse(event.data));
          }
        } catch (error) {
          console.error("Error processing WebSocket User List message:", error);
          // Handle the error, but do not close the WebSocket connection
        }
      };

      this.websocketChatList.onclose = (event) => {
        console.log("WebSocket User List connection closed");
        // Handle WebSocket connection closed
        if (event.code !== 1000 && event.code !== 1005) {
          this.reconnectWebSocket("USERLIST");
        }
      };

      this.websocketChatList.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  };

  setupWebSocketThreadList(onOpenCallback) {
    this.token = JSON.parse(localStorage.getItem("data"))?.token
    if (this.token) {
      this.websocketThreadList = new WebSocket(this.websocketUserListURL + "?token=" + this.token);

      // WebSocket event listeners
      this.websocketThreadList.onopen = () => {
        console.log("WebSocket thread List connection opened");
        if (onOpenCallback) {
          onOpenCallback();
        }
      };

      this.websocketThreadList.onmessage = (event) => {
        try {
          // Check if a callback is set and call it with the received message
          if (this.threadlistCallback) {
            this.threadlistCallback(JSON.parse(event.data));
          }
        } catch (error) {
          console.error("Error thread processing WebSocket User List message:", error);
          // Handle the error, but do not close the WebSocket connection
        }
      };

      this.websocketThreadList.onclose = (event) => {
        console.log("WebSocket thread List connection closed");
        // Handle WebSocket connection closed
        if (event.code !== 1000 && event.code !== 1005) {
          this.reconnectWebSocket("THREAD");
        }
      };

      this.websocketThreadList.onerror = (error) => {
        console.error('WebSocket thread error:', error);
      };
    }
  };

  setupWebSocketTopicList(onOpenCallback) {
    this.token = JSON.parse(localStorage.getItem("data"))?.token
    if (this.token) {
      this.websocketTopicList = new WebSocket(this.websocketUserListURL + "?token=" + this.token);

      // WebSocket event listeners
      this.websocketTopicList.onopen = () => {
        console.log("WebSocket topic List connection opened");
        if (onOpenCallback) {
          onOpenCallback();
        }
      };

      this.websocketTopicList.onmessage = (event) => {
        try {
          // Check if a callback is set and call it with the received message
          if (this.topiclistCallback) {
            this.topiclistCallback(JSON.parse(event.data));
          }
        } catch (error) {
          console.error("Error topic processing WebSocket User List message:", error);
          // Handle the error, but do not close the WebSocket connection
        }
      };

      this.websocketTopicList.onclose = (event) => {
        console.log("WebSocket topic List connection closed");
        // Handle WebSocket connection closed
        if (event.code !== 1000 && event.code !== 1005) {
          this.reconnectWebSocket("TOPIC");
        }
      };

      this.websocketTopicList.onerror = (error) => {
        console.error('WebSocket topic error:', error);
      };
    }
  };

  // Function to set a callback for received messages
  setMessageCallback(callback) {
    this.messageCallback = callback;
  }

  setUselistCallback(callback) {
    this.uselistCallback = callback;
  }

  setChatUselistCallback(callback) {
    this.chatuselistCallback = callback;
  }

  setThreadlistCallback(callback) {
    this.threadlistCallback = callback;
  }

  setTopiclistCallback(callback) {
    this.topiclistCallback = callback;
  }

  // Function to handle WebSocket reconnection
  reconnectWebSocket = (sockettype) => {
    if (sockettype === "USERLIST") {
      setTimeout(() => {
        this.setupWebSocketUserList(); // Reconnect by calling the setupWebSocket function again
      }, 500);
    } else if (sockettype === "CHAT") {
      setTimeout(() => {
        this.setupWebSocket(); // Reconnect by calling the setupWebSocket function again
      }, 500);
    }else if (sockettype === "THREAD") {
      setTimeout(() => {
        this.setupWebSocketThreadList(); // Reconnect by calling the setupWebSocket function again
      }, 500);
    }else if (sockettype === "TOPIC") {
      setTimeout(() => {
        this.setupWebSocketTopicList(); // Reconnect by calling the setupWebSocket function again
      }, 500);
    }
  };

  // Close WebSocket connection
  closeWebSocket = () => {
    if (this.websocket) {
      this.websocket.close();
      this.token = null
    }
    else if (this.websocketUserList) {
      this.websocketUserList.close();
      this.token = null
    }

    if (this.websocketChatList) {
      this.websocketChatList.close();
      this.token = null
    }

    if (this.websocketThreadList) {
      this.websocketThreadList.close();
      this.token = null
    }

    if (this.websocketTopicList) {
      this.websocketTopicList.close();
      this.token = null
    }
  };

  // For Chat Conversation
  sendChatRequest = (request) => {
    if (this.websocket && this.websocket.readyState === WebSocket.CLOSED) {
      this.setupWebSocket();
    }
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(request);
    } else {
      console.error("WebSocket Chat connection not open.");
    }
  };

  // For User List 
  sendUserListRequest = (request) => {
    if (this.websocketUserList && this.websocketUserList.readyState === WebSocket.CLOSED) {
      this.setupWebSocketUserList();
    }
    if (this.websocketUserList && this.websocketUserList.readyState === WebSocket.OPEN) {
      this.websocketUserList.send(request);
    } else {
      console.error("WebSocket User List connection not open.");
    }
  };

  sendMessageListRequest = (request) => {
    if (this.websocketChatList && this.websocketChatList.readyState === WebSocket.CLOSED) {
      this.setupWebSocketChatList();
    }
    if (this.websocketChatList && this.websocketChatList.readyState === WebSocket.OPEN) {
      this.websocketChatList.send(request);
    } else {
      console.error("WebSocket Message List connection not open.");
    }
  };

  sendThreadListRequest = (request) => {
    if (this.websocketThreadList && this.websocketThreadList.readyState === WebSocket.CLOSED) {
      this.setupWebSocketThreadList();
    }
    if (this.websocketThreadList && this.websocketThreadList.readyState === WebSocket.OPEN) {
      this.websocketThreadList.send(request);
    } else {
      console.error("WebSocket Thread List connection not open.");
    }
  };

  sendTopicListRequest = (request) => {
    if (this.websocketTopicList && this.websocketTopicList.readyState === WebSocket.CLOSED) {
      this.setupWebSocketTopicList();
    }
    if (this.websocketTopicList && this.websocketTopicList.readyState === WebSocket.OPEN) {
      this.websocketTopicList.send(request);
    } else {
      console.error("WebSocket Topic List connection not open.");
    }
  };

}
export default new ApiService();

import { useState } from "react";
import InvitePeople from "./InvitePeople";
import HomeScreen from "./HomeScreen";
import vector from "../assets/vector.svg"
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";
import SelectAgency from "./SelectAgency";

const CreateAgency = () => {
  const [showComponent, setShowComponent] = useState(null);
  const [agencyName, setAgencyName] = useState("");
  const [errors, setErrors] = useState({});

  const OnCreate = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!agencyName.trim()) {
      newErrors.email = 'AgencyName is required';
      toast.error('Agency name is required');
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = { full_name: agencyName, product_userid: window.product_userId };

      try {
        const response = await apiService.post("shopperpad/create-agency/", postData);
        if (response.status === 'success') {
          window.agencyName = agencyName;
          window.roleId = 1;
          window.agencyId = response.id;
          localStorage.setItem('data', JSON.stringify({ 'product_userId': window.product_userId, 'agencyId': window.agencyId, 'firstName': window.firstName, 'lastName': window.lastName, 'mobile_number': window.mobile_number, "token": window.token, "agencyName": window.agencyName, "roleId": window.roleId, "email": window.email }));
          toast.success(response.msg);
          setShowComponent('OnCreate');
        } else {
          toast.error(response.msg);
        }
        return response;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          toast.error("Internal Server Error. Please try again later.");
        } else if (error.response && error.response.status === 404) {
          toast.error("Resource not found.");
        } else {
          toast.error(
            `Error: ${error.response ? error.response.status : "unknown"}`
          );
        }
        return error;
      }
    }
  };

  const OnBack = () => {
    setShowComponent('SelectAgency');
  };

  if (showComponent === 'OnCreate') {
    return <HomeScreen />;
  }
  else if (showComponent === 'SelectAgency') {
    return <SelectAgency />;
  }
  else {
    return (
      <div className="w-full relative bg-white h-[1024px] flex flex-col items-center justify-center p-16 box-border" id="createagency-dv">
        <section
          className="max-w-[400px]  flex-1 rounded-lg bg-gray box-border flex flex-col items-center justify-start p-6 gap-[40px] text-left text-9xl text-headings font-base-md-regular border-[1px] border-solid border-stroke-light"
          id="create_agency"
        >
          <div className="self-stretch box-border h-12 flex flex-row items-center justify-start border-[1px] border-solid border-slategray-200" id="agency-nav">
            <div className="w-10 h-10 flex flex-row items-center justify-center">
              <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 relative h-6 overflow-hidden shrink-0" onClick={OnBack}>
                <img
                  className="absolute h-[56.25%] w-[27.92%] top-[22.08%] right-[36.25%] bottom-[21.67%] left-[35.83%] max-w-full overflow-hidden max-h-full"
                  alt=""
                  src={vector}
                />
              </button>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
              Create Agency
            </h2>
            <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%] text-paragraph">
              What’s the name of your agency?
            </p>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[16px] text-sm">
            <input
              className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-stroke"
              placeholder="Agency name"
              type="text"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
            />
            <p className="m-0 self-stretch relative tracking-[0.02em] leading-[140%]">
              <span>{`By continuing, you’re agreeing to our `}</span>
              <span className="text-secondary-button-link">
                Services Agreement
              </span>
              <span>{`, `}</span>
              <span className="text-secondary-button-link">
                User Terms of Services
              </span>
              <span>{`. Additional disclosers are available in our `}</span>
              <span className="text-secondary-button-link">Privacy Policy</span>
              <span>{` and `}</span>
              <span className="text-secondary-button-link">Cookie Policy</span>
            </p>
          </div>
          <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-center justify-end" onClick={OnCreate}>
            <div className="self-stretch rounded-13xl bg-primary-button-link h-12 flex flex-row items-center justify-center">
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Create
              </b>
            </div>
          </button>
        </section>
      </div>
    );
  }
};

export default CreateAgency;

import Closeicon from "../assets/Close-icon.svg"
import apiService from "../utils/ApiService";
import icon_attachement from "../assets/icon_attachement@2x.png";
import { useState, useCallback, useEffect, useRef } from "react";
import { formatMessageTimeForChat } from "../utils/TimeFormat";
import { v4 as uuidv4 } from 'uuid';
import TextWithLink from "../components/TextWithLink";
import { app, database } from "../config/firebase";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";

const GroupChatThread = (props) => {
  const [messages, setMessages] = useState([]);
  const [textMsg, setTextMsg] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileUri, setFileUri] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [pastedImage, setPastedImage] = useState(null);
  const { selectedMessage, user_image, onClose, groupId } = props;
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  let scrollToBottomCalled = false

  const myThreadRef = useRef(null)
  const pastedImageRef = useRef(null);

  useEffect(() => {
    // Setup the WebSocket connection and get the history when the WebSocket connection is opened. This is called by the web socket
    apiService.setupWebSocketThreadList(() => {
      setTimeout(() => {
        console.log("WebSocket connection in Thread");
        getHistroy(window.product_userId, '');
      }, 1000); // Call getHistory when the WebSocket connection is opened
    });
    fetchData();
  }, []);

  // Fetch data from API and store in cache. This is called when we have a new message
  const fetchData = async () => {
    try {
      //await getLocalStorage();
      // Set up callback for received messages
      apiService.setThreadlistCallback((temmessage) => {
        // Update state with the new message
        // debugger;
        if (temmessage.messages) {
          setMessages([])
          const newMessagesArray = temmessage.messages
            .filter((element) => element.content !== '' &&
              !messages.some((msg) => msg.message_uuid === temmessage.messages.message_uuid)) // Filter out messages with null content
            .map((element) => ({
              _id: element.id,
              text: element.content,
              lastMessageTime: formatMessageTimeForChat(element.created_at),
              userId: element.user_id,
              type: element.sender_id === window.product_userId ? 'outgoing' : 'incoming',
              read: element.isread,
              fileUri: element.attachement_url,
              fileType: element.attachment_type,
              fileName: element.attachment_name,
              message_uuid: element.message_uuid,
              reply_message: element.reply_message,
              reply_message_id: element.reply_message_id,
              reply_message_type: element.reply_message_type,
              reply_message_name: element.reply_message_name,

              topic_id: element.topic_id,
              thread_id: element.thread_id,
              total_thread_count: element.total_thread_count,
              thread_id_no: element.thread_id_no,
              userName: element.first_name + (element.last_name ? " " + element.last_name : ""),

            }));
          // Update state by merging the new messages array with the existing messages

          setMessages((prevMessages) => [...prevMessages, ...newMessagesArray]);

          if (!scrollToBottomCalled) {
            scrollToBottomCalled = true;
            setTimeout(() => {
              scrollToBottom();
            }, 2000);
          }
          // if (temmessage.messages.length <= 20) {
          //   if (selectedItem.id != window.product_userId) {
          //     readMessage(window.product_userId);
          //   }
          // }

        }
        else if (temmessage.status) {
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.map((message) => {
              return { ...message, read: true };
            });
            return updatedMessages;
          });
        }
        else {
          const messageData = temmessage;
          if (
            (messageData.content !== undefined || messageData.attachement_url !== undefined) &&
            !messages.some((msg) => msg.message_uuid === messageData.message_uuid)
          ) {
            const newMessage = {
              _id: messageData.id,
              text: messageData.content,
              userId: messageData.sender_id,
              lastMessageTime: formatMessageTimeForChat(messageData.created_at),
              type: messageData.sender_id === window.product_userId ? 'outgoing' : 'incoming',
              read: messageData.isread,
              fileUri: messageData.attachement_url,
              fileType: messageData.attachment_type,
              fileName: messageData.attachment_name,
              message_uuid: messageData.message_uuid,
              reply_message: messageData.reply_message,
              reply_message_id: messageData.reply_message_id,
              reply_message_type: messageData.reply_message_type,
              reply_message_name: messageData.reply_message_name,
              topic_id: messageData.topic_id,
              thread_id: messageData.thread_id,
              total_thread_count: messageData.total_thread_count,
              thread_id_no: messageData.thread_id_no,
              userName: messageData.first_name + (messageData.last_name ? " " + messageData.last_name : ""),
            };

            setMessages((prevMessages) => {
              if (!prevMessages.some((msg) => msg.message_uuid === newMessage.message_uuid)) {

                if (messageData.sender_id != window.product_userId)
                  readMessage(window.product_userId)
                const newArray = Array.isArray(newMessage) ? newMessage : [newMessage];

                // Prepend the new messages to the existing messages
                const updatedMessages = [...newArray, ...prevMessages];

                // Sort the messages by the created_at field in descending order
                const sortedMessages = updatedMessages.sort((a, b) =>
                  new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()
                );
                return sortedMessages;
              }
              return prevMessages;
            });
          }

          setTimeout(() => {
            scrollToBottom();
          }, 2000);

        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getHistroy = async (userId, messageId) => {
    const getmessageobj = {
      type: "get_conversation",
      receiver: 0,
      sender: parseInt(window.product_userId),
      ...(messageId ? { message_id: messageId } : {}),
      group_id: groupId,
      agency_id: 0,
      topic_id: 0,
      thread_id: selectedMessage.thread_id_no ? selectedMessage.thread_id_no : selectedMessage.id,
    }
    apiService.sendThreadListRequest(JSON.stringify(getmessageobj));
    console.log("get_conversation for thread: " + JSON.stringify(getmessageobj));
  }

  const handleDownload = (fileUri) => {
    if (fileUri) {
      window.open(fileUri, '_blank');
    }
  };

  const handleFileClick = () => {
    document.getElementById('fileInput').click();
  };

  function scrollToBottom() {
    myThreadRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  async function handleScroll(event) {
    const { scrollTop } = event.target;

    if (scrollTop === 0 && !isFetchingMore && messages.length > 0) {
      setIsFetchingMore(true);
      try {
        const lastMessageId = messages[messages.length - 1]._id;
        await getHistroy(window.product_userId, lastMessageId);
        event.target.scrollTop = 30
      } catch (error) {
        console.error('Error fetching more messages:', error);
      } finally {
        setIsFetchingMore(false);
      }
    }
  };


  const onSend = useCallback(async () => {
    if (pastedImage && (!pastedImageRef.current || pastedImageRef.current !== pastedImage)) {
      pastedImageRef.current = pastedImage;
      uploadMediaToFirestore(pastedImage);
      setPastedImage(null);
      return;
    }
    if (selectedMessage.thread_id_no || selectedMessage.id) {
      if ((textMsg !== null && textMsg.trim() !== '') || fileUri !== null) {
        const sendobj = {
          message_uuid: uuidv4(),
          sender: parseInt(window.product_userId),
          receiver: 0,
          message: textMsg,
          attachment: fileUri,
          attachment_type: fileType,
          attachment_name: fileName,
          reply: null,
          group_id: groupId,
          message_id: selectedMessage._id,
          topic_id: 0,
          thread_id: selectedMessage.thread_id_no ? selectedMessage.thread_id_no : selectedMessage.id,
        }
        apiService.sendThreadListRequest(JSON.stringify(sendobj));
        console.log("Send Json: " + JSON.stringify(sendobj));
      }
      else
        return
    }
    else {
      console.log("Create selectedMessage.thread_id_no: ");
      await createThreadRequest()
    }
    setTextMsg('');
    setFileType(null);
    setFileUri(null);
    setFileName(null);
    scrollToBottom();
  }, [textMsg, messages, fileUri, fileName, fileType, pastedImage]);

  const createThreadRequest = async () => {
    const textToSend = selectedMessage.text && selectedMessage.text.length > 250 ?
      selectedMessage.text.substring(0, 250) :
      selectedMessage.text;

    const postData = { "name": selectedMessage.fileType === 'image' ? selectedMessage.fileUri : textToSend, "user_id": window.product_userId, "group_id": groupId, "members": [selectedMessage.userId, window.product_userId], "message_id": selectedMessage._id };
    try {
      console.log('Create Thread POST request:', postData);
      const response = await apiService.post('shopperpad/add-thread/', postData);
      console.log('Create Topic Thread response:', JSON.stringify(response));
      if (response.status_code === 200) {
        if ((textMsg !== null && textMsg.trim() !== '') || fileUri !== null) {
          selectedMessage.thread_id_no = response.data.id;
          const sendobj = {
            message_uuid: uuidv4(),
            sender: parseInt(window.product_userId),
            receiver: 0,
            message: textMsg,
            attachment: fileUri,
            attachment_type: fileType,
            attachment_name: fileName,
            reply: null,
            group_id: response.data.group,
            topic_id: 0,
            thread_id: selectedMessage.thread_id_no ? selectedMessage.thread_id_no : selectedMessage.id,
            message_id: selectedMessage._id,
          }
          // debugger;
          apiService.sendThreadListRequest(JSON.stringify(sendobj));
          console.log("Send Thread Json: " + JSON.stringify(sendobj));
        }
        else
          return
      }
      else if (response.status_code === HttpStatusCode.BadRequest) {
        toast.error(response.msg);
      }
      else {
        toast.error(response.msg);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message || 'An error occurred');
      } else {
        toast.error('An error occurred');
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    uploadMediaToFirestore(file, "")
  };

  const uploadMediaToFirestore = async (res) => {
    const fileSizeLimit = 5 * 1024 * 1024;
    if (res.size > fileSizeLimit) {
      toast.error("File size exceeds the limit (5 MB).")
      return;
    }

    const filename = `${res.name}_${Date.now()}`;

    const storage = getStorage(app);
    const fileRef = ref(storage, filename);

    const contentType = res.type;

    let tempfiletype;
    let tempfilename = res.name;
    if (res.type.includes("image")) {
      tempfiletype = "image";
    } else if (res.type.includes("audio")) {
      tempfiletype = "audio";
    } else {
      tempfiletype = "document";
    }

    // Fetch the media content with the appropriate content type.
    const response = await fetch(URL.createObjectURL(res), {
      headers: { "Content-Type": contentType },
    });
    const blob = await response.blob();
    const metadata = { contentType };

    uploadBytes(fileRef, blob, metadata)
      .then((uploadTask) => {
        getDownloadURL(uploadTask.ref)
          .then(async (url) => {
            toast.success("File uploaded!");
            setFileUri(url);
            setFileType(tempfiletype);
            setFileName(tempfilename);
            await onSend({ url, contentType: tempfiletype, filename: tempfilename });
          })
          .catch((error) => {
            toast.error("error while downloading the file.");
            console.error(error);
          });
      })
      .catch((error) => {
        toast.error("error while uploading the file.");
        console.error(error);
      });
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    let blob = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        blob = items[i].getAsFile();
        break;
      }
    }
    if (blob !== null) {
      setPastedImage(blob);
    }
  };

  // Marks a message as read in the chat. This is called when someone visits the page
  const readMessage = async (userId) => {
    const getmessageobj = {
      type: "mark_read",
      receiver: selectedMessage.thread_id_no ? selectedMessage.thread_id_no : selectedMessage.id,
      sender: parseInt(userId),
    }
    console.log("mark_read: " + JSON.stringify(getmessageobj));
    apiService.sendHomeListRequest(JSON.stringify(getmessageobj));
  };

  function handleKeyDown(e) {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      setTextMsg(textMsg + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend({ url: '', contentType: '', filename: '' });
    }
  };

  function renderSelectedMessageContent() {
    if (selectedMessage && selectedMessage.fileType === 'image') {
      return (
        <a href={selectedMessage.fileUri} target="_blank" rel="noopener noreferrer">
          <img src={selectedMessage.fileUri} alt="Attached Image" className="w-20 h-15 object-cover" />
        </a>
      );
    } else {
      return selectedMessage.text.length > 17 ? (
        <>
          {`${selectedMessage.text.substring(0, 17)}...`}
        </>
      ) : (
        selectedMessage.text
      );
    }
  }

  return (
    <div className="w-[360px] shadow-[-2px_0px_40px_rgba(0,_0,_0,_0.08)] bg-white h-[100vh] flex flex-col items-center justify-center" id="right-sidebar">
      <header
        className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px] text-left text-lg text-headings font-base-md-regular absolute top-0 z-10"
        id="user-sidebar-dv"
      >
        {/* <div className="flex flex-row items-center justify-center p-2">
          <img
            className="w-[25px] relative h-[25px] overflow-hidden shrink-0"
            alt=""
            src="/icon-menu.svg"
          />
        </div> */}
        <div className="w-[143px] flex flex-row items-center justify-start">
          <b className="relative tracking-[0.01em] leading-[140%]">
            {renderSelectedMessageContent()}
          </b>
        </div>
        {/* <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src="/icon-new-chat.svg"
          />
        </div>
        <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src="/icon-new-chat1.svg"
          />
        </div> */}
        <div onClick={onClose}>
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src={Closeicon}
          />
        </div>
      </header>
      {/* <div
        className="self-stretch flex-1 flex flex-col items-start justify-start p-6" 
        id="user-content-sidebar-dv"
      > */}
      <div className="self-stretch flex-1 overflow-auto flex flex-col items-center justify-start z-0" style={{ paddingTop: "80px" }} onScroll={handleScroll}>
        <div className="self-stretch flex flex-col items-start justify-center py-2.5 px-4">
          {messages ? (
            messages.slice().reverse().map((item) => (
              <div className="self-stretch flex flex-row items-start justify-start text-base" id="inner-chat-blg" key={item.id}>
                <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[0]"
                    alt=""
                    src={user_image} />
                  {/* <img
                                            className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] h-3 object-cover z-[1]"
                                            alt=""
                                            src={user_online} /> */}
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                  <div className="self-stretch flex flex-row items-center justify-start py-0 px-2 gap-[8px]">
                    <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                      {item.userName}
                    </div>
                    <div className="relative text-xs leading-[120%]">
                      {item.lastMessageTime}
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-center py-1 px-2 gap-[4px] text-paragraph">
                    <div className="self-stretch relative leading-[140%]" >
                      <TextWithLink inputText={item.text} isReply={false} />
                      {item.fileType === 'image' && (
                        <div>
                          <a href={item.fileUri} target="_blank" rel="noopener noreferrer">
                            <img src={item.fileUri} alt="Attached Image" className="w-40 h-40 object-cover" />
                          </a>
                        </div>
                      )}
                      {item.fileType !== 'image' && item.fileUri && (
                        <div className="flex items-center justify-start">
                          <button onClick={() => handleDownload(item.fileUri)} className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md">
                            <span className="truncate">{item.fileName}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
          <div ref={myThreadRef}></div>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="self-stretch flex flex-col items-start justify-start p-4 text-sm text-placeholder-text"> */}
      <div className="self-stretch rounded overflow-hidden flex flex-col items-start justify-start border-[1px] border-solid border-stroke-light">
        <textarea
          className="[border:none] [outline:none] font-base-md-regular text-16 bg-white self-stretch h-12 flex flex-row items-center justify-start py-2 px-4 box-border text-placeholder-text"
          placeholder="Type your message"
          type="text"
          value={textMsg}
          onChange={(e) => setTextMsg(e.target.value)}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
        {pastedImage && (
          <>
            <img src={URL.createObjectURL(pastedImage)} alt="pasted Image" className="w-40 h-40 object-cover" />
            <button onClick={() => setPastedImage(null)}>
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src={Closeicon}

              />
            </button>
          </>
        )}
        <div className="self-stretch bg-stroke-light h-12 flex flex-row items-center justify-between py-0 px-4 box-border text-center text-xs text-app-background">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            {/* <div className="w-6 h-6 flex flex-col items-center justify-center">
                <img
                  className="w-[9.6px] relative h-[13px]"
                  alt=""
                  src="/b.svg"
                />
              </div> */}
            {/* <div className="w-6 h-6 flex flex-col items-center justify-center">
                <img
                  className="w-[7.3px] relative h-[14.2px]"
                  alt=""
                  src="/.svg"
                />
              </div> */}
            {/* <div className="w-6 h-6 flex flex-col items-center justify-center">
                <img
                  className="w-[9.7px] relative h-[13.3px]"
                  alt=""
                  src="/s.svg"
                />
              </div> */}
            {/* <div className="flex flex-col items-center justify-center">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/otllink.svg"
                />
              </div> */}
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px]">
            {/* <div className="h-6 flex flex-col items-center justify-center">
                <img
                  className="w-6 relative h-6"
                  alt=""
                  src={vector}
                />
              </div> */}
            {/* <div className="flex flex-col items-center justify-center">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/srpattach.svg"
                />
              </div> */}
            <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-center justify-center">
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                alt=""
                src={icon_attachement}
                onClick={handleFileClick} />
              <input id="fileInput" type="file" onChange={(e) => handleFileChange(e)} hidden={true} />
            </button>
            <button className={`cursor-pointer [border:none] py-2 px-6 bg-primary-button-link rounded h-8 flex flex-row items-center justify-center box-border ${(!textMsg || textMsg.trim() === '') && !pastedImage ? 'disabled' : ''}`} onClick={(messages) => onSend(messages)} type="submit">
              <b className="relative text-xs leading-[120%] uppercase font-base-md-regular text-app-background text-center">
                Send
              </b>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default GroupChatThread;

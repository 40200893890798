// Create a function to format the message timestamp
export const formatMessageTime = (timestamp) => {
    //console.log("Timestamp: " + timestamp);
    const messageDate = new Date(timestamp);
    const currentDate = new Date();

    // Check if the message date is the same as the current date (ignoring time)
    if (
        messageDate.getDate() === currentDate.getDate() &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
    ) {
        // If the message was sent today, display only the time
        console.log("Formatted Date Time: " + messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
        return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    } else if (
        messageDate.getDate() === currentDate.getDate() - 1 &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
    ) {
        // If the message was sent yesterday, return "Yesterday"
        //console.log("Formatted Date Time: Yesterday");
        return 'Yesterday';
    } else {
        // If the message was sent on a different day, display both date and time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            //hour: '2-digit',
            //minute: '2-digit',
            //hour12: true
        };

        const formattedDate = messageDate.toLocaleString('en-US', options);
        // console.log("Formatted Date Time: " + formattedDate);
        return formattedDate;
    }

}


// Create a function to format the message timestamp
export const formatMessageTimeForChat = (timestamp) => {
    //console.log("Timestamp: " + timestamp);
    const messageDate = new Date(timestamp);
    const currentDate = new Date();

    // Check if the message date is the same as the current date (ignoring time)
    if (
        messageDate.getDate() === currentDate.getDate() &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
    ) {
        // If the message was sent today, display only the time
        console.log("Formatted Date Time: " + messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
        return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    } else if (
        messageDate.getDate() === currentDate.getDate() - 1 &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
    ) {
        // If the message was sent yesterday, return "Yesterday"
        //console.log("Formatted Date Time: Yesterday");
        return 'Yesterday';
    } else {
        // If the message was sent on a different day, display both date and time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const formattedDate = messageDate.toLocaleString('en-US', options);
        // console.log("Formatted Date Time: " + formattedDate);
        return formattedDate;
    }

}
import { useState } from "react";
import FrameComponent from "../components/FrameComponent";
import Login from "./Login";
import EnterCode from "./EnterCode";
import carejot_logo from "../assets/carejot_logo@2x.png"
import slider_icon from "../assets/slider_icon_01@2x.png"
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";

const Signup = () => {
  const [showComponent, setShowComponent] = useState(null);
  const [name, setName] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const onLoginClick = () => {
    setShowComponent('Login');
  }

  const onSendCode = async (event) => {
    event.preventDefault();
    const newErrors = {};
    const countryCodePattern = /^\+\d{2}$/;
    if (!name.trim()) {
      newErrors.name = 'Name is required';
      toast.error('Name is required');
    } else if (!mobile.trim()) {
      newErrors.mobile = 'Mobile number is required';
      toast.error('Mobile number is required');
    } else if (!countryCodePattern.test(mobileCode)) {
      newErrors.countryCode = 'Invalid country code';
      toast.error('Invalid country code');
    } else if (!email.trim()) {
      newErrors.email = 'Email is required';
      toast.error('Email is required');
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = 'Email is invalid';
      toast.error('Email is invalid');
    } else if (/[A-Z]/.test(email)) {
      newErrors.email = 'Email is invalid';
      toast.error('Email is invalid');
    } else if (!/[a-zA-Z]/.test(name)) {
      newErrors.name = 'Name is required';
      toast.error('Enter a valid name using only alphabetic characters.');
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = { email: email, first_name: name, mobile_number: mobile };

      try {
        const response = await apiService.post("shopperpad/register/", postData);
        if (response.status_code === 200) {
          window.email = email;
          toast.success(response.msg);
          setShowComponent('EnterCode');
        } else {
          toast.error(response.msg);
        }
        return response;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          toast.error("Internal Server Error. Please try again later.");
        } else if (error.response && error.response.status === 404) {
          toast.error("Resource not found.");
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        }
        else {
          toast.error(
            `Error: ${error.response ? error.response.status : "unknown"}`
          );
        }
        return error;
      }
    }
  };

  const handleCountryCodeChange = (e) => {
    const value = e.target.value;
    const isValidMobile = /^\+\d*$/.test(value);
    if (isValidMobile) {
      setMobileCode(value);
    } else {
      setMobileCode('');
    }
  };

  const handleMobileKeyPress = (event) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '+', 'Tab', 'Shift'];
    const regex = /^[0-9]*$/;
    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleMobileCodeKeyPress = (event) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '+', 'Tab', 'Shift'];
    const regex = /^[0-9+]*$/;
    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  if (showComponent === 'Login') {
    return (<Login />);
  } else if (showComponent === 'EnterCode') {
    return <EnterCode />;
  }
  else {
    return (
      <div className="w-full relative bg-white h-[1024px] flex flex-row flex-wrap items-center justify-start" id="signup-main-dv">
        <section
          className="self-stretch flex-1 bg-stroke-light flex flex-col items-start justify-start p-16 box-border gap-[40px] min-w-[360px]"
          id="content_pan"
        >
          <img
            className="w-36 relative h-[51.4px] object-cover"
            alt="CareJOT"
            src={carejot_logo}
          />
          <FrameComponent slideIcon={slider_icon} />
        </section>
        <section
          className="self-stretch w-[453px] flex flex-col items-center justify-center p-16 box-border gap-[40px] min-w-[360px] text-left text-9xl text-headings font-base-md-regular"
          id="form_pan"
        >
          <div className="w-full flex flex-col items-center justify-start gap-[8px] max-w-[456px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
              Sign Up
            </h2>
            <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%] text-paragraph">
              Create your account to get started.
            </p>
          </div>
          <form
            className="m-0 w-full flex flex-col items-center justify-center gap-[24px] max-w-[456px]"
            id="signup_form" onSubmit={(e) => onSendCode(e)}
          >
            <input
              className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-stroke"
              placeholder="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="self-stretch flex flex-row items-start justify-end gap-[8px]">
              <input
                className="[outline:none] font-base-md-regular text-base bg-white w-[65px] 
              rounded-lg box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-headings 
              border-[1.5px] border-solid border-stroke"
                placeholder="+01"
                type="text"
                value={mobileCode}
                onKeyDown={handleMobileCodeKeyPress}
                onChange={(e) => handleCountryCodeChange(e)}
              />
              <input
                className="[outline:none] font-base-md-regular text-base bg-white w-[65px] flex-1 rounded-lg box-border 
              h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-stroke"
                placeholder="Mobile"
                type="text"
                value={mobile}
                onKeyDown={handleMobileKeyPress}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <input
              className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg 
            box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] 
            border-solid border-stroke"
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="w-[325px] hidden flex-col items-end justify-start gap-[8px]">
              <div className="self-stretch rounded-lg bg-white box-border h-12 flex flex-row items-center justify-start py-2 px-3 gap-[4px] border-[1.5px] border-solid border-slategray-100">
                <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">
                  Password
                </div>
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="./assets/button-show-password.svg"
                />
              </div>
              <div className="self-stretch rounded-lg overflow-hidden flex flex-row items-start justify-start gap-[4px]">
                <div className="flex-1 relative bg-gainsboro-100 h-1" />
                <div className="flex-1 relative bg-gainsboro-100 h-1" />
                <div className="flex-1 relative bg-gainsboro-100 h-1" />
              </div>
              <div className="self-stretch rounded-lg overflow-hidden flex flex-row items-start justify-start gap-[4px]">
                <div className="flex-1 relative bg-danger-active h-1" />
                <div className="flex-1 relative bg-warning-active h-1" />
                <div className="flex-1 relative bg-success-active h-1" />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="w-[258px] flex flex-row items-center justify-start gap-[2px]">
                  <img
                    className="w-3 relative h-3 overflow-hidden shrink-0"
                    alt=""
                    src="./assets/ioniconccheckmarkccircle.svg"
                  />
                  <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">
                    Lower case
                  </div>
                </div>
                <div className="w-[258px] flex flex-row items-center justify-start gap-[2px]">
                  <div className="w-3 relative rounded-[50%] bg-gainsboro-200 h-3" />
                  <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">
                    Upper case
                  </div>
                </div>
                <div className="w-[258px] flex flex-row items-center justify-start gap-[2px]">
                  <div className="w-3 relative rounded-[50%] bg-gainsboro-200 h-3" />
                  <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">
                    Number
                  </div>
                </div>
                <div className="w-[258px] flex flex-row items-center justify-start gap-[2px]">
                  <div className="w-3 relative rounded-[50%] bg-gainsboro-200 h-3" />
                  <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">{`Special character `}</div>
                </div>
                <div className="w-[258px] flex flex-row items-center justify-start gap-[2px]">
                  <div className="w-3 relative rounded-[50%] bg-gainsboro-200 h-3" />
                  <div className="flex-1 relative text-base leading-[120%] font-base-md-regular text-lightsteelblue text-left">
                    More than 8 character
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="cursor-pointer [border:none] p-0 bg-primary-button-link self-stretch rounded-13xl h-12 flex flex-row items-center justify-center">
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Send Code
              </b>
            </button>
          </form>
          <div className="w-full flex flex-row items-center justify-center gap-[8px] max-w-[456px] text-center text-base">
            <b className="relative tracking-[0.01em] leading-[140%]">
              Already Have Account?
            </b>
            <a
              className="[text-decoration:none] relative tracking-[0.01em] leading-[140%] font-bold text-secondary-button-link"
              href="#" onClick={onLoginClick}
            >
              Login
            </a>
          </div>
        </section>
      </div>
    );
  }
};

export default Signup;

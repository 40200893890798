import Closeicon from "../assets/Close-icon.svg"
import apiService from "../utils/ApiService";
import otlperson from "../assets/otlperson.svg";
import { useState, useEffect } from "react";

const GroupChatPeople = (props) => {
  const { onClose, groupId } = props
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.post("shopperpad/get-group-list/", {
          group_id: groupId
        });
        if (response.status === 'success') {
          setUsers(response.members);
        } else {
          console.error('error:', response.msg);
        }
      } catch (error) {
        console.error('error fetching data:', error);
      }
    };

    fetchData();
  }, [groupId]);

  return (
    <div className="w-[360px] shadow-[-2px_0px_40px_rgba(0,_0,_0,_0.08)] bg-white h-[100vh] flex flex-col items-center justify-center" id="right-sidebar">
      <div className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]">
        <div className="flex-1 flex flex-row items-center justify-start" id="right-sidebar">
          <b className="relative tracking-[0.01em] leading-[140%]">
            People ({users.length})
          </b>
        </div>
        <div className="flex flex-row items-center justify-center p-2" onClick={onClose}>
          <img
            className="w-[25px] relative h-[25px] overflow-hidden shrink-0"
            alt=""
            src={Closeicon}
          />
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start p-4 text-base">
        <div className="self-stretch flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-center p-3 text-secondary-button-link">
            {/* <b className="flex-1 relative tracking-[0.01em] leading-[140%]">
              + Add People
            </b> */}
          </div>
          {users.map((user) => (
            <div key={user.id} className="self-stretch flex flex-row items-center justify-start py-2.5 px-4">
              <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full z-[0]"
                    alt=""
                    src={otlperson}
                  />
                  {/* <div className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] bg-primary-bright box-border h-3 z-[1] border-[1.5px] border-solid border-app-background" /> */}
                </div>
                <div className="flex-1 flex flex-row items-end justify-start">
                  <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                    {user.last_name === null ? user.first_name : `${user.last_name}, ${user.first_name}`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupChatPeople;
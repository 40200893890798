import { useCallback, useEffect, useRef, useState } from "react";
import Closeicon from "../assets/Close-icon.svg"
import icon_attachement from "../assets/icon_attachement@2x.png";
import apiService from "../utils/ApiService";
import user_image from "../assets/user_image@2x.png"
import { v4 as uuidv4 } from 'uuid';
import { formatMessageTimeForChat } from "../utils/TimeFormat";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { app, database } from "../config/firebase";
import TextWithLink from "./TextWithLink";

const GroupChatTopicDetail = (props) => {
  const { onClose, topic, groupId } = props;
  const [topicMessages, setTopicMessages] = useState([])
  const [inputText, setInputText] = useState("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileUri, setFileUri] = useState(null);
  const [pastedImage, setPastedImage] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  let scrollToBottomCalled = false

  const myTopicRef = useRef(null)
  const pastedImageRef = useRef(null);

  useEffect(() => {
    apiService.setupWebSocketTopicList(async () => {
      await fetchGetHistroy();
    });
    fetchData();
  }, []);


  const fetchGetHistroy = async () => {
    getHistroy(window.product_userId, "")
  };

  const getHistroy = async (userId, messageId) => {
    const getmessageobj = {
      type: "get_conversation",
      receiver: 0,
      sender: parseInt(userId),
      ...(messageId ? { message_id: messageId } : {}),
      group_id: groupId,
      agency_id: 0,
      topic_id: topic.id,
      thread_id: 0
    }
    apiService.sendTopicListRequest(JSON.stringify(getmessageobj));
  }

  const fetchData = async () => {
    try {
      apiService.setTopiclistCallback((temmessage) => {
        // Update state with the new message
        if (temmessage.messages) {
          const newMessagesArray = temmessage.messages
            .filter((element) => {
              return element.content !== '' &&
                !topicMessages.some((msg) => msg.message_uuid === element.message_uuid)
            })
            .map((element) => ({
              _id: element.id,
              text: element.content,
              lastMessageTime: formatMessageTimeForChat(element.created_at),
              userId: element.user_id,
              type: element.sender_id === window.product_userId ? 'outgoing' : 'incoming',
              read: element.isread,
              fileUri: element.attachement_url,
              fileType: element.attachment_type,
              fileName: element.attachment_name,
              // userImage: data.profileimage,
              message_uuid: element.message_uuid,
              reply_message: element.reply_message,
              reply_message_id: element.reply_message_id,
              reply_message_type: element.reply_message_type,
              reply_message_name: element.reply_message_name,
              topic_id: element.topic_id,
              thread_id: element.thread_id,
              total_thread_count: element.total_thread_count,
              thread_id_no: element.thread_id_no,
              userName: element.first_name + (element.last_name ? " " + element.last_name : ""),
              message: element.content
            }));
          // Update state by merging the new messages array with the existing messages

          setTopicMessages((prevMessages) => [...prevMessages, ...newMessagesArray]);
          
          if (!scrollToBottomCalled) {
            scrollToBottomCalled = true;
            setTimeout(() => {
              scrollToBottom();
            }, 2000);
          }

          // if (temmessage.messages.length <= 20) {
          //   if (selectedItem.id != userId) {
          //     readMessage(userId);
          //   }
          // }
          // setIsLoading(false);
        }
        else if (temmessage.status) {
          setTopicMessages((prevMessages) => {
            const updatedMessages = prevMessages.map((message) => {
              return { ...message, read: true };
            });
            return updatedMessages;
          });
        }
        else {
          const messageData = temmessage;
          if (
            (messageData.content !== undefined || messageData.attachement_url !== undefined) &&
            !topicMessages.some((msg) => msg.message_uuid === messageData.message_uuid)
          ) {
            const newMessage = {
              _id: messageData.id,
              text: messageData.content,
              userId: messageData.sender_id,
              lastMessageTime: formatMessageTimeForChat(messageData.created_at),
              type: messageData.sender_id === window.product_userId ? 'outgoing' : 'incoming',
              read: messageData.isread,
              fileUri: messageData.attachement_url,
              fileType: messageData.attachment_type,
              fileName: messageData.attachment_name,
              // userImage: data.profileimage,
              message_uuid: messageData.message_uuid,
              reply_message: messageData.reply_message,
              reply_message_id: messageData.reply_message_id,
              reply_message_type: messageData.reply_message_type,
              reply_message_name: messageData.reply_message_name,
              topic_id: messageData.topic_id,
              thread_id: messageData.thread_id,
              total_thread_count: messageData.total_thread_count,
              thread_id_no: messageData.thread_id_no,
              userName: messageData.first_name + (messageData.last_name ? " " + messageData.last_name : ""),
              message: messageData.content
            };

            setTopicMessages((prevMessages) => {
              if (!prevMessages.some((msg) => msg.message_uuid === newMessage.message_uuid)) {

                if (messageData.sender_id != window.product_userId)
                  readMessage(window.product_userId)
                const newArray = Array.isArray(newMessage) ? newMessage : [newMessage];

                // Prepend the new messages to the existing messages
                const updatedMessages = [...newArray, ...prevMessages];

                // Sort the messages by the created_at field in descending order
                const sortedMessages = updatedMessages.sort((a, b) =>
                  new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()
                );
                return sortedMessages;
              }
              return prevMessages;
            });
          }
          // setIsLoading(false);
          setTimeout(() => {
            scrollToBottom();
          }, 2000);
        }
      });
    } catch (error) {
      // setIsLoading(false);
      console.error('Error fetching data:', error);
    } finally {
      // setIsLoading(false);
    }
  };

  const readMessage = async (userId) => {
    const getmessageobj = {
      type: "mark_read",
      receiver: userId,
      sender: parseInt(window.product_userId)
    }
    apiService.sendTopicListRequest(JSON.stringify(getmessageobj));
  };

  function scrollToBottom() {
    myTopicRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  async function handleScroll(event) {
    const { scrollTop } = event.target;

    if (scrollTop === 0 && !isFetchingMore && topicMessages.length > 0) {
      setIsFetchingMore(true);
      try {
        const lastMessageId = topicMessages[topicMessages.length - 1]._id;
        await getHistroy(window.product_userId, lastMessageId);
        event.target.scrollTop = 30
      } catch (error) {
        console.error('Error fetching more messages:', error);
      } finally {
        setIsFetchingMore(false);
      }
    }
  };

  const onSend = useCallback(async ({ url, contentType, filename }) => {
    if (pastedImage && (!pastedImageRef.current || pastedImageRef.current !== pastedImage)) {
      pastedImageRef.current = pastedImage;
      uploadMediaToFirestore(pastedImage);
      setPastedImage(null);
      return;
    }

    if (inputText.trim() || url) {
      const sendObj = {
        message_uuid: uuidv4(),
        receiver: 0,
        sender: window.product_userId,
        group_id: groupId,
        message: inputText,
        topic_id: topic.id,
        thread_id: 0,
        attachment: url,
        attachment_type: contentType,
        attachment_name: filename,
      }

      apiService.sendTopicListRequest(JSON.stringify(sendObj));

      setInputText('');
      setFileUri('');
      setFileType('');
      setFileName('');
      scrollToBottom();
    } else {
      return;
    }
  }, [inputText, topicMessages, fileUri, fileType, fileName, pastedImage]);

  const handleDownload = (fileUri) => {
    if (fileUri) {
      window.open(fileUri, '_blank');
    }
  };

  const handleTopicFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    uploadMediaToFirestore(file, "")
  };

  const handleFileClick = () => {
    document.getElementById('topicFileInput').click();
  };

  const uploadMediaToFirestore = async (res) => {
    const fileSizeLimit = 5 * 1024 * 1024;
    if (res.size > fileSizeLimit) {
      toast.error("File size exceeds the limit (5 MB).")
      return;
    }

    const filename = `${res.name}_${Date.now()}`;

    const storage = getStorage(app);
    const fileRef = ref(storage, filename);

    const contentType = res.type;

    let tempfiletype;
    let tempfilename = res.name;
    if (res.type.includes("image")) {
      tempfiletype = "image";
    } else if (res.type.includes("audio")) {
      tempfiletype = "audio";
    } else {
      tempfiletype = "document";
    }

    // Fetch the media content with the appropriate content type.
    const response = await fetch(URL.createObjectURL(res), {
      headers: { "Content-Type": contentType },
    });
    const blob = await response.blob();
    const metadata = { contentType };

    uploadBytes(fileRef, blob, metadata)
      .then((uploadTask) => {
        getDownloadURL(uploadTask.ref)
          .then(async (url) => {
            toast.success("File uploaded!");
            setFileUri(url);
            setFileType(tempfiletype);
            setFileName(tempfilename);
            await onSend({ url, contentType: tempfiletype, filename: tempfilename });
          })
          .catch((error) => {
            toast.error("error while downloading the file.");
            console.error(error);
          });
      })
      .catch((error) => {
        toast.error("error while uploading the file.");
        console.error(error);
      });
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    let blob = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        blob = items[i].getAsFile();
        break;
      }
    }
    if (blob !== null) {
      setPastedImage(blob);
    }
  };

  function handleKeyDown(e) {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      setInputText(inputText + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend({ url: '', contentType: '', filename: '' });
    }
  };

  return (
    <div className="w-[360px] shadow-[-2px_0px_40px_rgba(0,_0,_0,_0.08)] bg-white h-[60vh] flex flex-col items-center justify-center relative overflow-hidden">
      <div className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px] absolute top-0 left-0 right-0 z-10">
        <div className="flex flex-row items-center justify-center p-2">
          {/* <img
                  className="w-[25px] relative h-[25px] overflow-hidden shrink-0"
                  alt=""
                  src="/icon-menu.svg"
                /> */}
        </div>
        <div className="flex-1 flex flex-row items-center justify-start">
          <b className="relative tracking-[0.01em] leading-[140%]">
            {topic.name}
          </b>
        </div>
        {/* <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon-new-chat.svg"
                />
              </div>
              <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon-new-chat1.svg"
                />
              </div> */}
        <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2" onClick={onClose}>
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src={Closeicon}
          />
        </div>
      </div>
      {/* <div className="self-stretch flex-1 flex flex-col items-start justify-start p-4 text-base"> */}
      <div className="self-stretch flex-1 overflow-auto flex flex-col items-center justify-start z-0 " style={{ paddingTop: "120px" }} onScroll={handleScroll}>
        <div className="w-[355px] flex flex-col items-start justify-center py-2.5 px-4 box-border">
          {topicMessages.slice().reverse().map((msg) => (
            <div className="self-stretch flex flex-row items-start justify-start" key={msg.id}>
              <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full z-[0]"
                  alt=""
                  src={user_image}
                />
                <div className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] bg-primary-bright box-border h-3 z-[1] border-[1.5px] border-solid border-app-background" />
              </div>
              <div className="flex-1 flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-row items-center justify-start py-0 px-2 gap-[8px]">
                  <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                    {msg.userName}
                  </div>
                  {/* <div className="relative text-xs leading-[120%]">
                      {msg.lastMessageTime}
                    </div> */}
                </div>
                <div className="self-stretch rounded flex flex-col items-start justify-center py-1 px-2 text-paragraph">
                  <div className="self-stretch relative leading-[140%]">
                    <TextWithLink inputText={msg.message} isReply={false} />
                    {msg.fileType === 'image' && (
                      <div>
                        <a href={msg.fileUri} target="_blank" rel="noopener noreferrer">
                          <img src={msg.fileUri} alt="Attached Image" className="w-40 h-40 object-cover" />
                        </a>
                      </div>
                    )}
                    {msg.fileType !== 'image' && msg.fileUri && (
                      <div className="flex items-center justify-start">
                        <button onClick={() => handleDownload(msg.fileUri)} className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md">
                          <span className="truncate">{msg.fileName}</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div ref={myTopicRef}></div>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="self-stretch flex flex-col items-start justify-start p-4 text-sm text-placeholder-text absolute bottom-0 left-0 right-0 z-10" onKeyDown={onKeyDownHandler}> */}
      <div className="self-stretch rounded overflow-hidden flex flex-col items-start justify-start border-[1px] border-solid border-stroke-light">
        {/* <div className="relative leading-[120%]">Type your message</div> */}
        <textarea
          // className="relative leading-[120%] flex-1 outline-none"
          className="[border:none] [outline:none] font-base-md-regular text-16 bg-white self-stretch h-12 flex flex-row items-center justify-start py-2 px-4 box-border text-placeholder-text"
          placeholder="Type your message"
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
        {pastedImage && (
          <>
            <img src={URL.createObjectURL(pastedImage)} alt="pasted Image" className="w-40 h-40 object-cover" />
            <button onClick={() => setPastedImage(null)}>
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src={Closeicon}

              />
            </button>
          </>
        )}
        <div className="self-stretch bg-stroke-light h-12 flex flex-row items-center justify-between py-0 px-4 box-border text-center text-xs text-app-background">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            {/* <div className="w-6 h-6 flex flex-col items-center justify-center">
                      <img
                        className="w-[9.6px] relative h-[13px]"
                        alt=""
                        src="/b.svg"
                      />
                    </div>
                    <div className="w-6 h-6 flex flex-col items-center justify-center">
                      <img
                        className="w-[7.3px] relative h-[14.2px]"
                        alt=""
                        src="/.svg"
                      />
                    </div>
                    <div className="w-6 h-6 flex flex-col items-center justify-center">
                      <img
                        className="w-[9.7px] relative h-[13.3px]"
                        alt=""
                        src="/s.svg"
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <img
                        className="w-6 relative h-6 overflow-hidden shrink-0"
                        alt=""
                        src="/otllink.svg"
                      />
                    </div> */}
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <div className="cursor-pointer flex flex-col items-center justify-center">
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                alt=""
                src={icon_attachement}
                onClick={handleFileClick} />
              <input id="topicFileInput" type="file" onChange={(e) => handleTopicFileChange(e)} hidden={true} />
            </div>
            <div className={`cursor-pointer rounded bg-primary-button-link h-8 flex flex-row items-center justify-center py-2 px-6 box-border ${(!inputText || inputText.trim() === '') && !pastedImage ? 'disabled' : ''}`} onClick={(messages) => onSend(messages)} type="submit"  >
              <b className="relative leading-[120%] uppercase">Send</b>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default GroupChatTopicDetail;
import { useEffect, useState } from "react";
import Closeicon from "../assets/Close-icon.svg"
import CreateTopic from "./CreateTopic";
import GroupChatTopicDetail from "./GroupChatTopicDetails";
import ApiService from "../utils/ApiService";

const GroupChatTopicList = (props) => {
    const { onClose, groupId, onTopicDetailsClose, showTopicDeatils, onTopicDetailsOpen } = props;
    const [showCreateTopic, setShowCreateTopic] = useState(false)
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [shouldFetchData, setShouldFetchData] = useState(true);

    useEffect(() => {
        if (shouldFetchData) {
            fetchData();
            setShouldFetchData(false);
        }
    }, [shouldFetchData]);

    const fetchData = async () => {
        try {
            const response = await ApiService.post("shopperpad/get-group-list/", {
                group_id: groupId
            });
            if (response.status === 'success') {
                setTopics(response.topics);
            } else {
                console.error('error:', response.msg);
            }
        } catch (error) {
            console.error('error fetching data:', error);
        }
    };

    function handleCreateTopic() {
        setShowCreateTopic(true)
        onTopicDetailsClose()
    }

    function onCloseCreateTopic() {
        setShowCreateTopic(false)
        setShouldFetchData(true)
    }

    function handleTopicDetails(topic) {
        setSelectedTopic(topic);
        onTopicDetailsOpen()
        setShowCreateTopic(false)
    }

    return (
        <div className="w-[360px] shadow-[-2px_0px_40px_rgba(0,_0,_0,_0.08)] bg-white h-[100vh] flex flex-col items-center justify-center overflow-hidden" id="right-sidebar">
            <div className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]">
                {/* <div className="flex flex-row items-center justify-center p-2">
                <img
                  className="w-[25px] relative h-[25px] overflow-hidden shrink-0"
                  alt=""
                  src="/icon-menu.svg"
                />
              </div> */}
                <div className="flex-1 flex flex-row items-center justify-start">
                    <b className="relative tracking-[0.01em] leading-[140%]">
                        Topics
                    </b>
                </div>
                {/* <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon-new-chat.svg"
                />
              </div>
              <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2">
                <img
                  className="w-6 relative h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon-new-chat1.svg"
                />
              </div> */}
                <div className="rounded-[4.17px] flex flex-row items-center justify-center p-2" onClick={onClose}>
                    <img
                        className="w-6 relative h-6 overflow-hidden shrink-0"
                        alt=""
                        src={Closeicon}
                    />
                </div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start p-4 text-base">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start p-4 gap-[8px] border-b-[1px] ">
                    <b className="self-stretch relative tracking-[0.01em] leading-[140%] text-secondary-button-link pointercursor" onClick={handleCreateTopic}>
                        + Create Topic
                    </b>
                    {topics.map(topic => (
                        <div key={topic.id} className="self-stretch flex flex-row items-center justify-start py-3 px-0 gap-[8px]">
                            <div className="flex-1 relative leading-[120%] font-semibold pointercursor" onClick={() => handleTopicDetails(topic)}>
                                {topic.name}
                            </div>
                            {/* <div className="rounded bg-primary-bright flex flex-row items-center justify-center py-0.5 px-1.5 text-xs text-app-background">
                                <div className="relative leading-[120%] font-semibold">
                                    {topic.count}
                                </div>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
            {showCreateTopic && <CreateTopic groupId={groupId} onClose={onCloseCreateTopic} />}
            {showTopicDeatils && <GroupChatTopicDetail onClose={onTopicDetailsClose} topic={selectedTopic} groupId={groupId}/>}
        </div>
    );
};

export default GroupChatTopicList;
import { useEffect } from "react";
import Signup from "./pages/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./pages/HomeScreen";

function App() {
  useEffect(() => {
    if (localStorage.getItem('data') && localStorage.getItem('data') !== "{}") {
      let data = JSON.parse(localStorage.getItem('data'));
      window.product_userId = data.product_userId;
      window.agencyId = data.agencyId;
      window.firstName = data.firstName;
      window.lastName = data.lastName;
      window.mobile_number = data.mobile_number;
      window.token = data.token;
      window.agencyName = data.agencyName;
      window.roleId = data.roleId;
      window.email = data.email;
    }
  });

  return (
    <>
      {localStorage.getItem('data') ? (<HomeScreen />) :
        (<Signup />)}
      <ToastContainer
        autoClose={3000}
        theme="colored"
        hideProgressBar={true}
      />
    </>
  );
}

export default App;

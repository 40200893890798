import { useState } from "react";
import Signup from "./Signup";
import EnterCode from "./EnterCode";
import FrameComponent from "../components/FrameComponent";
import carejot_logo from "../assets/carejot_logo@2x.png"
import slider_icon from "../assets/slider_icon_01@2x.png"
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";

const Login = () => {
  const [showComponent, setShowComponent] = useState(null);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const onSignupClick = () => {
    setShowComponent('Signup');
  }

  const onSendCode = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!email.trim()) {
      newErrors.email = 'Email is required';
      toast.error('Email is required');
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = 'Email is invalid';
      toast.error('Email is invalid');
    } else if (/[A-Z]/.test(email)) {
      newErrors.email = 'Email is invalid';
      toast.error('Email is invalid');
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = { email: email, device_id: "web", device_type: "web" };

      try {
        const response = await apiService.post("shopperpad/login/", postData);
        if (response.status_code === 200) {
          window.email = email;
          toast.success(response.msg);
          setShowComponent('EnterCode');
        } else {
          toast.error(response.msg);
        }
        return response;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          toast.error("Internal Server Error. Please try again later.");
        } else if (error.response && error.response.status === 404) {
          toast.error("Resource not found.");
        } else {
          toast.error(
            `Error: ${error.response ? error.response.status : "unknown"}`
          );
        }
        return error;
      }
    }
  };

  if (showComponent === 'Signup') {
    return (<Signup />);
  } else if (showComponent === 'EnterCode') {
    return <EnterCode />;
  }
  else {
    return (
      <div className="w-full relative bg-white h-[1024px] flex flex-row flex-wrap items-center justify-start" id="login-main-dv">
        <section
          className="self-stretch flex-1 bg-stroke-light flex flex-col items-start justify-start p-16 box-border gap-[40px] min-w-[360px] text-left text-9xl text-primary-button-link font-base-md-regular"
          id="content_pan"
        >
          <img
            className="w-36 relative h-[51.4px] object-cover"
            alt="CareJOT"
            src={carejot_logo}
          />
          <FrameComponent slideIcon={slider_icon} />
        </section>
        <section
          className="self-stretch w-[453px] flex flex-col items-center justify-center p-16 box-border gap-[40px] min-w-[360px] text-left text-9xl text-headings font-base-md-regular"
          id="form_pan"
        >
          <div className="w-full flex flex-col items-start justify-start gap-[8px] max-w-[456px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
              Login
            </h2>
            <div className="self-stretch flex flex-col items-start justify-start text-base text-paragraph">
              <p className="m-0 self-stretch relative tracking-[0.02em] leading-[140%]">
                Please login to your account to continue.
              </p>
            </div>
          </div>
          <form className="m-0 self-stretch flex flex-col items-center justify-center gap-[24px]" onSubmit={(e) => onSendCode(e)}>
            <input
              className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-stroke"
              placeholder="Enter email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* {errors.email && <div>{errors.email}</div>} */}
            <button className="cursor-pointer [border:none] p-0 bg-primary-button-link self-stretch rounded-13xl h-12 flex flex-row items-center justify-center" type="submit">
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Send Code
              </b>
            </button>
          </form>
          <div className="self-stretch flex flex-row items-center justify-center gap-[8px] text-center text-base">
            <b className="relative tracking-[0.01em] leading-[140%]">
              Don’t Have Account?
            </b>
            <a
              className="[text-decoration:none] relative tracking-[0.01em] leading-[140%] font-bold text-secondary-button-link"
              href="#" onClick={onSignupClick}
            >
              Sign Up
            </a>
          </div>
        </section>
      </div>
    );
  }
};

export default Login;

import { useState } from "react";
import FrameComponent from "../components/FrameComponent";
import SelectAgency from "./SelectAgency";
import carejot_logo from "../assets/carejot_logo@2x.png";
import slider_icon from "../assets/slider_icon_01@2x.png";
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";

const EnterCode = () => {
  const [showComponent, setShowComponent] = useState(null);
  const [optcode, setOptCode] = useState("");
  const [errors, setErrors] = useState({});

  const verifyOTP = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!optcode.trim()) {
      newErrors.optcode = 'Enter valid code';
      toast.error('Enter valid code');
    } 
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = { email: window.email, otp: optcode, deviceId: "web", deviceType: "web" };

      try {
        const response = await apiService.post("shopperpad/verify-otp/", postData);
        if (response.status_code === 200) {
          toast.success(response.msg);
          window.product_userId = response.data.id;
          window.token = response.token;
          window.firstName = response.data.first_name;
          window.lastName = response.data.last_name;
          window.mobile_number = response.data.mobile_number;
          setShowComponent('SelectAgency');
        } else {
          toast.error(response.msg);
        }
        return response;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          toast.error("Internal Server Error. Please try again later.");
        } else if (error.response && error.response.status === 404) {
          toast.error("Resource not found.");
        }else if(error.response.status === 400) {
          toast.error(error.response.data.message);
        }
         else {
          toast.error(
            `Error: ${error.response ? error.response.status : "unknown"}`
          );
        }
        return error;
      }
    }
  };

  const onSendCode = async (event) => {
    const postData = { email: window.email };
    try {
      const response = await apiService.post("shopperpad/request-otp/", postData);
      if (response.status_code === 200) {
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
      return response;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Internal Server Error. Please try again later.");
      } else if (error.response && error.response.status === 404) {
        toast.error("Resource not found.");
      } else {
        toast.error(
          `Error: ${error.response ? error.response.status : "unknown"}`
        );
      }
      return error;
    }
  };

  if (showComponent === 'SelectAgency') {
    return <SelectAgency />;
  }
  else {
    return (
      <div className="w-full relative bg-white h-[1024px] flex flex-row flex-wrap items-center justify-start" id="entercode-dv">
        <section
          className="self-stretch flex-1 bg-stroke-light flex flex-col items-start justify-start p-16 box-border gap-[40px] min-w-[360px]"
          id="content_pan"
        >
          <img
            className="w-36 relative h-[51.4px] object-cover"
            alt="CareJOT"
            src={carejot_logo}
          />
          <FrameComponent slideIcon={slider_icon} />
        </section>
        <section
          className="self-stretch w-[453px] flex flex-col items-center justify-center p-16 box-border gap-[40px] min-w-[360px] text-left text-9xl text-headings font-base-md-regular"
          id="form_pan"
        >
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
              Enter code
            </h2>
            <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%] text-paragraph">
              Please enter the code we sent you to your email address.
            </p>
          </div>
          <form className="m-0 self-stretch flex flex-col items-end justify-start gap-[24px]" onSubmit={(e) => verifyOTP(e)}>
            <input
              className="[outline:none] font-base-md-regular text-base bg-white self-stretch rounded-lg box-border h-12 flex flex-row items-center justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-headings"
              placeholder="Enter code"
              type="text"
              value={optcode}
              onChange={(e) => setOptCode(e.target.value)}
            />
            <a
              className="[text-decoration:none] relative text-base tracking-[0.01em] leading-[140%] font-bold font-base-md-regular text-secondary-button-link text-right"
              href="#" onClick={(e) => onSendCode(e.target.value)}
            >
              Resend
            </a>
            <button className="cursor-pointer [border:none] p-0 bg-primary-button-link self-stretch rounded-13xl h-12 flex flex-row items-center justify-center" type="submit">
              <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                Get Started
              </b>
            </button>
          </form>
        </section>
      </div>
    );
  }
};

export default EnterCode;

// Imports and configures the firebase module. This is the function that is called by the ` initializeApp ` function
import { initializeApp } from 'firebase/app';
import { getAuth, initializeAuth, getReactNativePersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDISm_cKN-c8q_L3Tx5VgVXHt3Jb9chfr8",
  authDomain: "cogentchat-d371e.firebaseapp.com",
  projectId: "cogentchat-d371e",
  storageBucket: "cogentchat-d371e.appspot.com",
  messagingSenderId: "743427342014",
  appId: "1:743427342014:web:d1b1e4c38ec9f5641c28ef"
};


//export const auth = getAuth(app);

const app = initializeApp(firebaseConfig);
//const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
//export const auth = getAuth();
const database = getFirestore();
export { app, auth, database };
import { useState } from "react";
import icon_back from "../assets/icon_back@2x.png"
import InviteSent from "./InviteSent";
import apiService from "../utils/ApiService";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";

const InvitePeople = ({ refreshLeftNavigation, invitations }) => {
  const [showComponent, setShowComponent] = useState('Invite');
  const [selectedContacts, setSelectedContacts] = useState('');
  const onSetComponent = (text) => {
    setShowComponent(text);
  }

  async function postRequest (event) {
    event.preventDefault();
    const selectedContactsArray = selectedContacts.split(",");

    if (selectedContactsArray.length === 1) {
      const mobileNumber = selectedContactsArray[0];
      const alreadyInvitedContact = invitations.find(invitation => invitation.mobile_number === mobileNumber);
  
      if (alreadyInvitedContact) {
        // toast.error(`User with mobile number ${mobileNumber} is already invited`);
        toast.error("This user is already invited");
        return;
      }
    }

    const postData = [];
    const alreadyInvitedContacts = []

    for (let i = 0; i < selectedContactsArray.length; i++) {
      const mobileNumber = selectedContactsArray[i];

      const alreadyInvitedContact = invitations.find(invitation => invitation.mobile_number === mobileNumber);
      if (alreadyInvitedContact) {
        alreadyInvitedContacts.push(mobileNumber);
      } else {
        postData.push({ first_name: '', last_name: '', mobile_number: mobileNumber, countryCode: '', user: window.product_userId, subscriber: window.agencyId, });
      }
    }

    if (alreadyInvitedContacts.length > 0) {
      toast.error(`Users with mobile numbers ${alreadyInvitedContacts.join(', ')} are already invited`);
      return;
    }

    try {
      const response = await apiService.post('shopperpad/user-invitation/', postData);
      setSelectedContacts([]);

      if (response.status_code === 200) {
        toast.success("Invitation sent successfully");
        refreshLeftNavigation()
      }
      else if (response.status_code === HttpStatusCode.BadRequest) {
        if (response.error === 'Duplicate key violation') {
          toast.error("This user is already invited");
        } else {
          toast.error(response.msg || 'Bad Request error');
        }
      }
      else if (response.status_code === 108) {
        toast.error("This user is already invited");
      }
      else {
        toast.error('Request failed, Please try again later');
        setSelectedContacts([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const response = error.response.data;
        if (response.error === 'Duplicate key violation') {
          toast.error('This user is already invited');
        } else {
          toast.error(response.msg);
        }
      } else {
        toast.error('Request failed, Please try again later');
        setSelectedContacts([]);
      }
    }
  };

  const handleKeyPress = (event) => {
    const regex = /^[0-9+,]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    // <div className="w-full relative bg-white h-[1024px] flex flex-row flex-wrap items-center justify-start" id="invitepepople-dv">
    <div className="w-[1080px] flex flex-row items-center justify-start chatMain">
      <section
        className="self-stretch flex-1 bg-white flex flex-col items-center justify-center min-w-[360px] text-left text-lg text-headings font-base-md-regular"
        id="content_pan"
      >
        <div
          className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]"
          id="content_pan_header"
        >
          <button className="cursor-pointer [border:none] p-2 bg-[transparent] flex flex-row items-center justify-center">
            <img
              className="w-[25px] relative h-[25px] overflow-hidden shrink-0 object-cover"
              alt=""
              src={icon_back}
            />
          </button>
          <div className="flex-1 flex flex-row items-center justify-start">
            <b className="relative tracking-[0.01em] leading-[140%]">
              Invitation
            </b>
          </div>
        </div>
        <div
          className="self-stretch flex-1 flex flex-col items-start justify-start text-5xl"
          id="tab_cotainer"
        >
          <div className="self-stretch flex flex-row items-start justify-start border-[1px] border-solid border-stroke-light w-[100%] tab_cotainer-dv">
            <button className={`cursor-pointer [border:none] py-[13px] px-10 bg-[transparent] flex flex-row items-center ${showComponent === 'Invite' && 'justify-center border-b-[4px] border-solid border-primary-button-link'}`} onClick={() => onSetComponent('Invite')}>
              <b className={`relative text-base tracking-[0.01em] leading-[140%] font-base-md-regular text-center ${showComponent === 'Invite' ? 'text-primary-button-link' : 'text-headings'}`}>
                Invite
              </b>
            </button>
            <button className={`cursor-pointer [border:none] py-[13px] px-10 bg-[transparent] flex flex-row items-center justify-center ${showComponent === 'Sent' && 'justify-center border-b-[4px] border-solid border-primary-button-link'}`} onClick={() => onSetComponent('Sent')}>
              <b className={`relative text-base tracking-[0.01em] leading-[140%] font-base-md-regular text-left ${showComponent === 'Sent' ? 'text-primary-button-link' : 'text-headings'}`}>
                Sent
              </b>
            </button>
          </div>
          {showComponent === 'Invite' ? (<div
            className="self-stretch flex-1 flex flex-col items-center justify-center p-16"
            id="tab_conent_invite"
          >
            <div
              className="min-w-[454px] flex-1 rounded-lg bg-gray box-border flex flex-col items-center justify-start p-6 gap-[40px] 
              border-[1px] border-solid border-stroke-light min-w-[360px]"
              id="invite_people"
            >
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <h2 className="m-0 self-stretch relative text-inherit tracking-[0.02em] leading-[120%] font-bold font-inherit">
                  Invite People
                </h2>
                <p className="m-0 self-stretch relative text-base tracking-[0.02em] leading-[140%] text-paragraph">
                  Add comma separated mobile numbers to invite people to your
                  agency
                </p>
              </div>
              <form className="m-0 self-stretch flex flex-col items-center justify-start gap-[16px]" onSubmit={(e) => postRequest(e)}>
                <textarea
                  className="bg-white font-base-md-regular text-base [outline:none] self-stretch rounded box-border h-[184px] flex flex-row items-start justify-start py-2 px-3 text-placeholder-text border-[1.5px] border-solid border-stroke" id="textarea-dv"
                  placeholder="Mobile number"
                  value={selectedContacts}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setSelectedContacts(e.target.value)}
                />
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-center justify-end" type="submit">
                  <div className="self-stretch rounded-13xl bg-primary-button-link h-12 flex flex-row items-center justify-center">
                    <b className="flex-1 relative text-base leading-[120%] font-base-md-regular text-white text-center">
                      Create
                    </b>
                  </div>
                </button>
              </form>
            </div>
          </div>) : (
            <InviteSent refreshLeftNavigation={refreshLeftNavigation} />
          )}
        </div>
      </section>
    </div>
  );
};

export default InvitePeople;

import { useState, useEffect } from "react";
import apiService from "../utils/ApiService";
import { formatMessageTime } from "../utils/TimeFormat";
import SelectAgency from "./SelectAgency";
import Signup from "./Signup";
import LeftSidebar from "../components/LeftSidebar";
import ContentPannel from "../components/ContentPannel";

const HomeScreen = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState(null);
  const [boardGroups, setBoardGroups] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(null);
  const [groupdata, setGroupData] = useState({});
  const [showAgencySelect, setShowAgencySelect] = useState(true);
  const [showSignupSelect, setSignupSelect] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState()

  useEffect(() => {
    apiService.setupWebSocketUserList(() => {
      setShowAgencySelect('')
      setSignupSelect('');
         fetchUserList();
    });
  }, []);

  const fetchUserList = async () => {
    // { "type":"get_user_list", "user_id":103, "subscriber_id": 97 }
    const getmessageobj = {
      user_id: window.product_userId,
      type: "get_user_list",
      subscriber_id: window.agencyId
    };
    apiService.sendUserListRequest(JSON.stringify(getmessageobj));
  };

  useEffect(() => {
    userListCallBack();
  }, []);

  const refreshLeftNavigation = async (param) => {
    if (!param) {
      setShowComponent('');
    }
    await fetchUserList();
  };

  const userListCallBack = async () => {
    apiService.setUselistCallback((response) => {
      // console.log("HomeScreenHome Response: " + JSON.stringify(response));
      window.homeResponse = response;
      if (response) {
        const updatedUserList = [];
        const updatedGroupList = [];
        const updatedInvitationsList = [];
        // Add users to the updated user list
        if (response.users && response.users.length > 0) {
          updatedUserList.push(
            ...response.users
              .filter(element => element.id !== window.product_userId)
              .map(element => ({
                firstname: element.first_name,
                lastname: element.last_name,
                profileimage: element.profile_image,
                email: element.email,
                id: element.id,
                lastmessage: element.last_message,
                isonline: element.isOnline,
                lastMessagetime: element.created_at ? formatMessageTime(element.created_at) : null,
                unread: element.unread,
                profile_image: element.profile_image,
                created_at: element.created_at,
              }))
          );
          setUsers(updatedUserList);
        }
        else
          setUsers([]);

        // Add groups to the updated user list
        if (response.groups && response.groups.length > 0) {
          console.log("Group Info: " + JSON.stringify(response.groups));
          updatedGroupList.push(
            ...response.groups
              .map(element => ({
                group_name: element.group_name,
                group_img_url: element.group_img_url,
                id: element.group_id,
                lastmessage: element.last_message,
                isonline: false, // Adjust this as per your requirements
                lastMessagetime: element.created_at ? formatMessageTime(element.created_at) : null,
                unread: element.unread, // Adjust this as per your requirements
                profile_image: element.profile_image,
                created_at: element.created_at,
                members: element.members,
                group_adminName: element.group_admin,
                group_adminId: element.group_admin_id
              }))
          );
          setBoardGroups(updatedGroupList.filter(group => group.group_name === 'general'));
          setGroups(updatedGroupList.filter(group => group.group_name !== 'general'));
        }
        else
          setGroups([]);

        // Add invited users to the updated user list
        if (response.invited && response.invited.length > 0) {
          updatedInvitationsList.push(
            ...response.invited.map((invite) => ({
              firstname: invite.first_name,
              lastname: invite.last_name,
              profileimage: null, // Adjust this as per your requirements
              email: '', // Adjust this as per your requirements
              id: invite.id,
              lastmessage: '', // Adjust this as per your requirements
              isonline: false, // Adjust this as per your requirements
              lastMessagetime: null, // Adjust this as per your requirements
              unread: 0, // Adjust this as per your requirements
              created_at: '', // Adjust this as per your requirements
              mobile_number: invite.mobile_number
            }))
          );
          setInvitations(updatedInvitationsList);
          // console.log("Home Invitations updated: " + JSON.stringify(updatedInvitationsList));
        }
        else
          setInvitations([]);
        //setUsers(updatedUserList);
        // console.log("Updated Users: ", users);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        setUsers([]);
        setInvitations([]);
        setGroups([]);
      }
    })
  };

  const onSetComponent = (text) => {
    setShowComponent(text);
  }

  const onChatComponent = (item) => {
    setSelectedItemId(item.id)
    setShowComponent('');
    setGroupData(item);
    item.unread = 0;
    setShowComponent('GroupChat');
  };

  const onAgencyClick = () => {
    setShowAgencySelect('SelectAgency');
  };

  const onLogOutClick = () => {
    onLogoutContainerClick();
    localStorage.removeItem('data');
    setSignupSelect('Signup');
  };

  const onLogoutContainerClick = async () => {
    const postData = {
      email: window.email,
      user_id: window.product_userId,
      device_type: "web"
    };

    const response = await apiService.post("shopperpad/logout/", postData);

    if (response.status == "success") {
      debugger;
      apiService.closeWebSocket();
      localStorage.clear();
    }
  };

  const sortedUsers = users.slice().sort((a, b) => {
    // If a's created_at is missing or greater than b's created_at, a comes later
    if (!a.created_at || (new Date(a.created_at) > new Date(b.created_at))) {
      return 1;
    }
    // If b's created_at is missing or greater than a's created_at, b comes later
    else if (!b.created_at || (new Date(b.created_at) > new Date(a.created_at))) {
      return -1;
    }
    // If both created_at are equal or both are null, maintain the original order
    else {
      return 0;
    }
  });

  if (showAgencySelect === 'SelectAgency') {
    return <SelectAgency />
  }
  else if (showSignupSelect === 'Signup') {
    return <Signup />;
  }
  else {
    return <div className="w-full relative bg-white h-[1024px] flex flex-row flex-wrap items-center justify-start text-left text-lg text-headings font-base-md-regular" id="groupchat-dv" key={groupdata.id}>
      <LeftSidebar
        boardGroups={boardGroups}
        groups={groups}
        users={users}
        sortedUsers={sortedUsers}
        invitations={invitations}
        onChatComponent={onChatComponent}
        onLogOutClick={onLogOutClick}
        onAgencyClick={onAgencyClick}
        onSetComponent={onSetComponent}
        selectedItemId={selectedItemId}
      />

      <ContentPannel
        showComponent={showComponent}
        groupdata={groupdata}
        refreshLeftNavigation={refreshLeftNavigation}
        invitations={invitations}
      />
    </div>
  }
};

export default HomeScreen;
